import { RentPaymentType } from "src/app/enums/Index";
import { Model } from "./Model";
import { RentCharge } from "./RentCharge";

export interface RentPayment extends Model {
	datePaid: Date;
	rentCharge?: RentCharge;
	rentChargeId: number;
	paymentType: RentPaymentType;
	paymentValue: number;
	receiptImageUrl?: string;
}

export class RentPayment implements RentPayment {
	constructor(data?: Partial<RentPayment>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}