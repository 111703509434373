import { Guid } from "guid-typescript";
import { FetchInterceptor } from "src/app/helpers/Index";
import { ChangePassword, RequestPasswordResetToken, ResetPasswordFromToken, User } from "src/app/interfaces/Index";
import { Service } from "./Service";

const endpoint = "User";
export const UserService = {
	endpoint,
	blockUser: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/BlockUser/${id}`, {method: "POST"}),
	blockReportedUser: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/BlockReportedUser/${id}`, {method: "POST"}),
	checkPasswordResetToken: async (token: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/CheckResetPasswordToken/${token}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	createSingle: async (data: User) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	loginCheck: async (groupName: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/LoginCheck/${groupName}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	me: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Me`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	requestPasswordReset: async (data: RequestPasswordResetToken) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RequestPasswordReset`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	requestPasswordResetForUser: async (userId: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RequestPasswordResetForUser${userId}`, {headers: {"Content-Type": "application/json"}, method: "POST"}),
	resetPasswordFromToken: async (data: ResetPasswordFromToken) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ResetPasswordFromToken`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	unblockUser: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/UnblockUser/${id}`, {method: "POST"}),
	updatePassword: async (data: ChangePassword) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/UpdatePassword`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	updateSingleById: async (id: number, data: User) => await Service.updateSingleById(endpoint, id, data),
	verifyEmailAddress: async (token: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/VerifyEmailAddress`, {body: JSON.stringify({EmailAddressVerificationCode: token}), headers: {"Content-Type": "application/json"}, method: "POST"})
}