import { Guid } from "guid-typescript";
import { FetchInterceptor } from "src/app/helpers/Index";
import { Property } from "src/app/interfaces/Index";
import { Service } from "./Service";

const endpoint = "Property";
export const PropertyService = {
	endpoint,
	createSingle: async (data: Property) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getFullDetails: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/GetFullDetails/${id}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getPerformanceByLandlord: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/GetPerformanceByLandlord/${id}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getPerformanceByProperty: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/GetPerformanceByProperty/${id}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getPerformanceSummaryByLandlord: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/GetPerformanceSummaryByLandlord/${id}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	updateSingleById: async (id: number, data: Property) => await Service.updateSingleById(endpoint, id, data)
}