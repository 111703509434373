import { Guid } from "guid-typescript";
import { RentPayment } from "src/app/interfaces/Index";
import { Service } from "./Service";

const endpoint = "RentPayment";
export const RentPaymentService = {
	endpoint,
	createSingle: async (data: RentPayment) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	updateSingleById: async (id: number, data: RentPayment) => await Service.updateSingleById(endpoint, id, data)
}