import { Model } from "./Model";
import { Property } from "./Property";

export interface Landlord extends Model {
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	emailAddress?: string;
	name?: string;
	phoneNumber?: string;
	postcode?: string;
	properties?: Property[];
	town?: string;
}

export class Landlord implements Landlord {
	constructor(data?: Partial<Landlord>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}