import { Route, Routes } from "react-router-dom";
import { Admin404NotFound, AdminDashboard, AdminForgotPassword, AdminHome404NotFound, AdminLandlords, AdminLandlordsId, AdminLogin, AdminMain, AdminProperties, AdminPropertiesId, AdminResetPassword, AdminSystemSettings, AdminTenancyAgreementId, AdminTenants, AdminTenantsId } from "src/app/pages/Index";

export const AppRoutes = ({toast}: any) => {
	return (
		<Routes>
			{/* Admin Routes */}
			<Route caseSensitive={true} element={<AdminLogin toast={toast}/>} path={`/`}/>
			<Route caseSensitive={true} element={<AdminLogin toast={toast}/>} path={`/${process.env.REACT_APP_BUILD_ADMINROUTE}`}/>
			<Route caseSensitive={true} element={<AdminForgotPassword toast={toast}/>} path={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/forgot-password`}/>
			<Route caseSensitive={true} element={<AdminResetPassword toast={toast}/>} path={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/reset-password/:token`}/>
			<Route caseSensitive={true} element={<AdminMain/>} path={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/main`}>
				<Route caseSensitive={true} element={<AdminDashboard/>} path=""/>
				<Route caseSensitive={true} element={<AdminLandlords toast={toast}/>} path="landlords"/>
				<Route caseSensitive={true} element={<AdminLandlordsId toast={toast}/>} path="landlords/:id"/>
				<Route caseSensitive={true} element={<AdminProperties toast={toast}/>} path="properties"/>
				<Route caseSensitive={true} element={<AdminPropertiesId toast={toast}/>} path="properties/:id"/>
				<Route caseSensitive={true} element={<AdminSystemSettings toast={toast}/>} path="system-settings/"/>
				<Route caseSensitive={true} element={<AdminTenancyAgreementId toast={toast}/>} path="tenancy-agreement/:id"/>
				<Route caseSensitive={true} element={<AdminTenants toast={toast}/>} path="tenants"/>
				<Route caseSensitive={true} element={<AdminTenantsId toast={toast}/>} path="tenants/:id"/>
				<Route caseSensitive={true} element={<AdminHome404NotFound/>} path="*"/>
			</Route>
			<Route caseSensitive={true} element={<Admin404NotFound/>} path={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/*`}/>
		</Routes>
	);
}