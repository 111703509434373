import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { colours } from "src/app/Styles";
import { ResetPasswordFromToken } from "src/app/interfaces/Index";
import { SystemSettingService, UserService } from "src/app/services/Index";

export const AdminResetPassword = ({toast}: any) => {
	const params = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [passwordComplexityRules, setPasswordComplexityRules] = useState("");
	const [requestSubmitted, setRequestSubmitted] = useState(false);
	const [resetPasswordFromToken, setResetPasswordFromToken] = useState<ResetPasswordFromToken>(new ResetPasswordFromToken());
	const [tokenChecked, setTokenChecked] = useState(false);
	const [validationErrors, setValidationErrors] = useState<{[key: string]: string[]}>({});

	// Constructor
	useEffect(() => {
		setIsLoading(true);
		getPasswordComplexityRules();
		getToken();
	}, []);

	const getPasswordComplexityRules = async () => {
		const res = await SystemSettingService.passwordComplexityRules();
		switch(res.status) {
			case 200:
				const data = await res.json();
				await setPasswordComplexityRules(data.rules);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				break;
		}
	}

	const getToken = async () => {
		const {token} = params;
		const res = await UserService.checkPasswordResetToken(token || "");
		await setTokenChecked(true);
		switch(res.status) {
			case 200:
				await setResetPasswordFromToken({...resetPasswordFromToken, token: token});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const renderForm = () => {
		return (
			<div style={{backgroundColor: colours.white, display: "flex", flexDirection: "column", justifyContent: "space-between", padding: 40, width: 600}}>
				<div>
					<span style={{color: colours.secondary, fontSize: 35, marginBottom: 10}}>Forgot Password</span>
					<InputText disabled={isLoading} id="newPassword" onChange={(e) => setResetPasswordFromToken({...resetPasswordFromToken, newPassword: e.target.value})} placeholder="New Password" style={{marginTop: 10, padding: "0.5rem", width: "100%"}} type="password" value={resetPasswordFromToken.newPassword}/>
					{validationErrors.newPassword &&
						<small id="newPassword-help" style={{color: colours.error, display: "block", fontSize: 12}}>{validationErrors.newPassword.join(" ")}</small>
					}
					<InputText disabled={isLoading} id="confirmNewPassword" onChange={(e) => setResetPasswordFromToken({...resetPasswordFromToken, confirmNewPassword: e.target.value})} placeholder="Confirm Password" style={{marginTop: 10, padding: "0.5rem", width: "100%"}} type="password" value={resetPasswordFromToken.confirmNewPassword}/>
					{validationErrors.confirmNewPassword &&
						<small id="confirmNewPassword-help" style={{color: colours.error, display: "block", fontSize: 12}}>{validationErrors.confirmNewPassword.join(" ")}</small>
					}
					<span style={{color: colours.secondary, display: "block", fontSize: 15, marginTop: 15}}>{passwordComplexityRules}</span>
				</div>
				<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
					<div></div>
					<Button iconPos="right" label="Save Changes" loading={isLoading} onClick={() => sendResetPassword()} style={{backgroundColor: colours.secondary, borderRadius: 20, borderWidth: 0, color: colours.primary, fontSize: 20, height: 50, minWidth: 150, width: "40%"}}/>
				</div>
			</div>
		);
	}

	const renderInvalidToken = () => {
		return (
			<div style={{backgroundColor: colours.white, display: "flex", flexDirection: "column", maxWidth: 600, padding: 40}}>
				<span style={{color: colours.secondary, fontSize: 20, marginBottom: 5}}>Invalid token provided. Either you have provided the wrong token, or your token has expired and can no longer be used.</span>
				<span style={{color: colours.secondary, fontSize: 20, marginBottom: 5}}>Please visit the <Link style={{color: colours.primary, display: "inline", fontSize: 20, marginTop: 10, textDecoration: "none"}} to={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/forgot-password`}>Forgotten Password Page</Link> in order to request a new token.</span>
			</div>
		);
	}

	const renderSuccess = () => {
		return (
			<div style={{backgroundColor: colours.white, display: "flex", flexDirection: "column", maxWidth: 600, padding: 40}}>
				<span style={{color: colours.secondary, fontSize: 20, marginBottom: 5}}>Your password has been successfully reset.</span>
				<span style={{color: colours.secondary, fontSize: 20, marginBottom: 5}}>You may now log in using the below link:</span>
				<Link style={{color: colours.primary, display: "block", fontSize: 20, marginTop: 10, textDecoration: "none"}} to={`/${process.env.REACT_APP_BUILD_ADMINROUTE}`}>Login</Link>
			</div>
		);
	}

	const sendResetPassword = async () => {
		await setIsLoading(true);
		await setValidationErrors({});
		const res = await UserService.resetPasswordFromToken(resetPasswordFromToken);
		if (res && res.ok) {
			if (toast.current) (toast.current as any).show({closable: false, detail: "Password reset successful.", life: 5000, severity: "success", summary: "Success."});
			await setRequestSubmitted(true);
		} else {
			const resDetails = await res.json();
			let toastDetail = "Please try again later.";
			if (resDetails.error.includes("Invalid Token")) toastDetail = resDetails.error;
			if (toast.current) (toast.current as any).show({closable: false, detail: toastDetail, life: 5000, severity: "error", summary: "Something went wrong!"});
			if (resDetails.validationErrors) await setValidationErrors(resDetails.validationErrors);
		}
		await setIsLoading(false);
	}

	return (
		<div style={{backgroundImage: "url(/assets/splash.jpg)", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "1920px 1080px", height: "100vh", width: "100vw"}}>
			<div style={{alignItems: "center", backgroundColor: colours.transparent, display: "flex", height: "100vh", justifyContent: "center", width: "100vw"}}>
				<div style={{backgroundColor: "rgb(250, 50, 50)", borderRadius: 20, borderWidth: 0, display: "flex", flexDirection: "row", overflow: "hidden"}}>
					<div style={{alignItems: "center", backgroundColor: colours.secondary, display: "flex"}}>
						<img alt="Logo" src="/assets/Logo_GoldCircleWithWhite.png" style={{height: "20vw", margin: "50px 25px 50px 25px", maxHeight: 300, maxWidth: 300, width: "20vw"}}/>
					</div>
					{tokenChecked === false ? null : requestSubmitted ? renderSuccess() : resetPasswordFromToken.token === undefined || resetPasswordFromToken.token === "" ? renderInvalidToken() : renderForm()}
				</div>
			</div>
		</div>
	);
}