import { Utility } from "src/app/enums/Index";
import { Model } from "./Model";
import { Property } from "./Property";

export interface MeterReading extends Model {
	property?: Property;
	propertyId: number;
	readingDate: Date;
	readingValue: number;
	utility: Utility;
}

export class MeterReading implements MeterReading {
	constructor(data?: Partial<MeterReading>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}