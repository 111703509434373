import { faHouse, faHouseBlank, faMoneyBill, faPerson, faSliders, faArrowRightFromBracket } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "primereact/menu";
import { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import "./AdminMain.css";
import { Button } from "primereact/button";
import { colours } from "src/app/Styles";
import { AppSettingsService } from "src/app/services/Index";

export const AdminMain = () => {
	const navigate = useNavigate();
	const [menuItems, setMenuItems] = useState<Array<any>>();

	useEffect(() => {
		getMenuItems();
	}, []);

	const getMenuItems = () => {
		setMenuItems([
			{icon: faHouseBlank, label: "Dashboard", template: (item: any) => renderMenuItem(item), to: `/${process.env.REACT_APP_BUILD_ADMINROUTE}/main`},
			{icon: faMoneyBill, label: "Landlords", template: (item: any) => renderMenuItem(item), to: `/${process.env.REACT_APP_BUILD_ADMINROUTE}/main/landlords`},
			{icon: faPerson, label: "Tenants", template: (item: any) => renderMenuItem(item), to: `/${process.env.REACT_APP_BUILD_ADMINROUTE}/main/tenants`},
			{icon: faHouse, label: "Properties", template: (item: any) => renderMenuItem(item), to: `/${process.env.REACT_APP_BUILD_ADMINROUTE}/main/properties`},
			{icon: faSliders, label: "Settings", template: (item: any) => renderMenuItem(item), to: `/${process.env.REACT_APP_BUILD_ADMINROUTE}/main/system-settings`}
		]);
	}

	const logout = async () => {
		await AppSettingsService.remove("accessToken");
		await AppSettingsService.remove("refreshToken");
		navigate("/admin");
	};

	const renderMenuItem = (item: any) => {
		return (
			<NavLink className="p-menuitem-link" end style={{position: "relative"}} to={item.to}>
				<FontAwesomeIcon icon={item.icon} style={{color: "#3C3C3C", fontSize: 15, width: 40}}/>
				<span className="p-menuitem-text" style={{color: "#3C3C3C", fontSize: 15}}>{item.label}</span>
			</NavLink>
		);
	}

	const renderStaging = () => {
		if(process.env.REACT_APP_BUILD_PRODUCTION == 'false'){
		return (
			<div style={{backgroundColor:"red", color:"white",marginTop:-5, padding:10, textAlign:"center"}}> THIS IS A STAGING SITE, THIS IS NOT A PRODUCTION SYSTEM</div>
		);}
	}

	return (
		<div style={{backgroundColor: "#EDF1F5", minHeight: "100vh", paddingTop: "4rem"}}>
			{renderStaging()}
			<div className="layout-topbar" style={{width: "100vw"}}>
				<Link className="layout-topbar-logo" style={{fontFamily: "Gilroy-Bold, sans-serif", textDecoration: "none"}} to={`/${process.env.REACT_APP_BUILD_ADMINROUTE}/main`}>
					<img alt="Logo" src="/assets/Logo_GoldCircleWithWhite.png" style={{height: 40}}></img>
				</Link>
				<Button onClick={() => logout()} style={{backgroundColor: colours.primary, borderColor: colours.primary, height: 40, justifyContent: "center", marginTop: 10, padding: 0, width: 40}}>
						<FontAwesomeIcon icon={faArrowRightFromBracket} style={{color: colours.black}}/>
					</Button>
			</div>
			<Menu model={menuItems}/>
			<div style={{marginLeft: 314, marginRight: 32, marginTop: 32, paddingBottom: 32, width: "calc(100% - 346px)"}}>
				<Outlet/>
			</div>
		</div>
	);
}