export interface EnumMap {
	id: number;
	name: string;
}

export class EnumMap implements EnumMap {
	id = -1;
	name = "";

	constructor(data: EnumMap) {
		for (const key of Object.keys(data)) {
			(this as any)[key] = (data as any)[key];
		}
	}
}