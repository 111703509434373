import { faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { colours } from "src/app/Styles";
import { PropertyType } from "src/app/enums/Index";
import { GetPropertiesFromEnum } from "src/app/helpers/Index";
import { Landlord, Property } from "src/app/interfaces/Index";
import { LandlordService, PropertyService } from "src/app/services/Index";

export const AdminProperties = ({toast}: any) => {
	const navigate = useNavigate();
	const dataTable = useRef(null);
	const [isAddPropertyLoading, setIsAddPropertyLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [landlords, setLandlords] = useState([] as Landlord[]);
	const [newProperty, setNewProperty] = useState<Property>(new Property());
	const [properties, setProperties] = useState([] as Property[]);
	const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);
	const propertyTypes = GetPropertiesFromEnum(PropertyType);

	useEffect(() => {
		getProperties();
		getLandlords();
	}, []);

	const createProperty = async () => {
		setIsAddPropertyLoading(true);
		const res = await PropertyService.createSingle(newProperty);
		switch(res.status) {
			case 200:
				getProperties();
				setIsAddPropertyLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddPropertyLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddPropertyLoading(false);
				break;
		}
	}

	const getLandlords = async () => {
		const res = await LandlordService.getAll();
		switch(res.status) {
			case 200:
				const data = await res.json();
				setLandlords(data);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				break;
		}
	}

	const getProperties = async () => {
		setIsLoading(true);
		const res = await PropertyService.getAll();
		switch(res.status) {
			case 200:
				const data = await res.json();
				setProperties(data);
				if (newProperty.addressLine1) {
					const fullNewProperty = data.find((landlord: Landlord) => landlord.addressLine1 === newProperty.addressLine1);
					navigate(`${fullNewProperty.id}`);
				}
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const gridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions">
					<Button onClick={() => navigate(`${rowData.id}`)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const renderAddPropertyModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowAddPropertyModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Property" onClick={() => createProperty()}></Button>
			</>
		);
	}

	const renderLandlord = (property: Property) => {
		let landlord = landlords.find(x=>x.id == property.landlordId);
		return(
			landlord?.name
		);
	}

	const renderLeftToolbarButtons = () => {
		return (
			<Button icon="pi pi-plus" label="Add New Property" style={{backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginLeft: 10}} onClick={() => setShowAddPropertyModal(true)}></Button>
		);
	}

	const renderRightToolbarButtons = () => {
		return (
			<Button icon="pi pi-download" label="Export" style={{backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10}} onClick={() => dataTable.current ? (dataTable.current as any).exportCSV() : null}></Button>
		);
	}

	return (
		<>
			<Row>
				<Col>
					<div className="card">
						<h5>Properties</h5>
						<Toolbar end={() => renderRightToolbarButtons()} start={() => renderLeftToolbarButtons()}></Toolbar>
						<DataTable loading={isLoading} ref={dataTable} scrollable scrollHeight="flex" style={{height: "calc(100vh - 17.5rem)"}} value={properties}>
							<Column body={gridButtons} style={{width: 50}}></Column>
							<Column field="addressLine1" header="Address" sortable></Column>
							<Column field="town" header="Town" sortable></Column>
							<Column field="postcode" header="Postcode" sortable></Column>
							<Column field="landlord.name" header="Landlord" body={renderLandlord} sortable></Column>
						</DataTable>
					</div>
				</Col>
			</Row>
			<Dialog className="p-fluid add-property" draggable={false} footer={() => renderAddPropertyModalFooter()} header="Add Property" modal onHide={() => setShowAddPropertyModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddPropertyModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="landlordId">Landlord</label>
							<Dropdown id="landlordId" onChange={(e) => setNewProperty({...newProperty, landlordId: e.value})} options={landlords} optionLabel="name" optionValue="id" style={{ width: "100%" }} value={newProperty.landlordId}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine1">Address Line 1</label>
							<InputText id="addressLine1" onChange={(e) => setNewProperty({...newProperty, addressLine1: e.target.value})} value={newProperty.addressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="addressLine2">Address Line 2</label>
							<InputText id="addressLine2" onChange={(e) => setNewProperty({...newProperty, addressLine2: e.target.value})} value={newProperty.addressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine3">Address Line 3</label>
							<InputText id="addressLine3" onChange={(e) => setNewProperty({...newProperty, addressLine3: e.target.value})} value={newProperty.addressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="town">Town</label>
							<InputText id="town" onChange={(e) => setNewProperty({...newProperty, town: e.target.value})} value={newProperty.town}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="postcode">Postcode</label>
							<InputText id="postcode" onChange={(e) => setNewProperty({...newProperty, postcode: e.target.value})} value={newProperty.postcode}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="propertyType">Property Type <span style={{color:"red"}}>*</span></label>
							<Dropdown id="propertyType" onChange={(e) => setNewProperty({...newProperty, propertyType: e.value})} options={propertyTypes} optionLabel="name" optionValue="id" required style={{ width: "100%" }} value={newProperty.propertyType}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="epcRating">EPC Rating</label>
							<Dropdown id="epcRating" onChange={(e) => setNewProperty({...newProperty, epcRating: e.value})} options={["A", "B", "C", "D", "E", "F", "G"]} showClear style={{ width: "100%" }} value={newProperty.epcRating}/>
						</div>
					</Col>
					<Col></Col>
				</Row>
				{isAddPropertyLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
		</>
	);
}