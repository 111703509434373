import { Guid } from "guid-typescript";
import { FetchInterceptor } from "src/app/helpers/Index";

export const Service = {
	createSingle: async (endpoint: string, data: any) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	deleteSingleById: async (endpoint: string, id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/${id}`, {headers: {"Content-Type": "application/json"}, method: "DELETE"}),
	getAll: async (endpoint: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}`, { method: "GET"}),
	getSingleById: async (endpoint: string, id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/${id}`, {method: "GET"}),
	getSingleByLookupId: async (endpoint: string, id: Guid) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/LookUp/${id}`, {method: "GET"}),
	updateSingleById: async (endpoint: string, id: number, data: any) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/${id}`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "PATCH"})
}