import { faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { colours } from "src/app/Styles";
import { Tenant } from "src/app/interfaces/Index";
import { TenantService } from "src/app/services/Index";

export const AdminTenants = ({toast}: any) => {
	const navigate = useNavigate();
	const dataTable = useRef(null);
	const [isAddTenantLoading, setIsAddTenantLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [newTenant, setNewTenant] = useState<Tenant>(new Tenant());
	const [tenants, setTenants] = useState([] as Tenant[]);
	const [showAddTenantModal, setShowAddTenantModal] = useState(false);

	useEffect(() => {
		getTenants();
	}, []);

	const createTenant = async () => {
		setIsAddTenantLoading(true);
		const res = await TenantService.createSingle(newTenant);
		switch(res.status) {
			case 200:
				getTenants();
				setIsAddTenantLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddTenantLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddTenantLoading(false);
				break;
		}
	}

	const getTenants = async () => {
		setIsLoading(true);
		const res = await TenantService.getAll();
		switch(res.status) {
			case 200:
				const data = await res.json();
				setTenants(data);
				if (newTenant.emailAddress) {
					const fullNewTenant = data.find((tenant: Tenant) => tenant.emailAddress === newTenant.emailAddress);
					navigate(`${fullNewTenant.id}`);
				}
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const gridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions">
					<Button onClick={() => navigate(`${rowData.id}`)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const renderAddTenantModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowAddTenantModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Tenant" onClick={() => createTenant()}></Button>
			</>
		);
	}

	const renderLeftToolbarButtons = () => {
		return (
			<Button icon="pi pi-plus" label="Add New Tenant" style={{backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginLeft: 10}} onClick={() => setShowAddTenantModal(true)}></Button>
		);
	}

	const renderRightToolbarButtons = () => {
		return (
			<Button icon="pi pi-download" label="Export" style={{backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10}} onClick={() => dataTable.current ? (dataTable.current as any).exportCSV() : null}></Button>
		);
	}

	return (
		<>
			<Row>
				<Col>
					<div className="card">
						<h5>Tenants</h5>
						<Toolbar end={() => renderRightToolbarButtons()} start={() => renderLeftToolbarButtons()}></Toolbar>
						<DataTable loading={isLoading} ref={dataTable} scrollable scrollHeight="flex" style={{height: "calc(100vh - 17.5rem)"}} value={tenants} filterDisplay="row">
							<Column body={gridButtons} style={{width: 50}}></Column>
							<Column field="name" header="Name" sortable filter filterMatchMode="contains"></Column>
						</DataTable>
					</div>
				</Col>
			</Row>
			<Dialog className="p-fluid add-tenant" draggable={false} footer={() => renderAddTenantModalFooter()} header="Add Tenant" modal onHide={() => setShowAddTenantModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddTenantModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="name">Name</label>
							<InputText id="name" onChange={(e) => setNewTenant({...newTenant, name: e.target.value})} value={newTenant.name}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="emailAddress">Email Address</label>
							<InputText id="emailAddress" onChange={(e) => setNewTenant({...newTenant, emailAddress: e.target.value})} value={newTenant.emailAddress}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine1">Forwarding Address Line 1</label>
							<InputText id="forwardingAddressLine1" onChange={(e) => setNewTenant({...newTenant, forwardingAddressLine1: e.target.value})} value={newTenant.forwardingAddressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine2">Forwarding Address Line 2</label>
							<InputText id="forwardingAddressLine2" onChange={(e) => setNewTenant({...newTenant, forwardingAddressLine2: e.target.value})} value={newTenant.forwardingAddressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine3">Forwarding Address Line 3</label>
							<InputText id="forwardingAddressLine3" onChange={(e) => setNewTenant({...newTenant, forwardingAddressLine3: e.target.value})} value={newTenant.forwardingAddressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="phoneNumber">Phone Number</label>
							<InputText id="phoneNumber" onChange={(e) => setNewTenant({...newTenant, phoneNumber: e.target.value})} value={newTenant.phoneNumber}/>
						</div>
					</Col>
				</Row>
				{isAddTenantLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
		</>
	);
}