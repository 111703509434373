import { EventType } from "src/app/enums/Index";
import { Model } from "./Model";
import { Property } from "./Property";

export interface PropertyEvent extends Model {
	amountSpent?: number;
	amountSpentOn?: Date;
	dateFixed?: Date;
	dateReported: Date;
	description?: string;
	descriptionOfSpending?: string;
	eventType: EventType;
	photos?: string;
	property?: Property;
	propertyId: number;
	videos?: string;
}

export class PropertyEvent implements PropertyEvent {
	constructor(data?: Partial<PropertyEvent>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}