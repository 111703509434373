import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { Link } from "react-router-dom";
import { colours } from "src/app/Styles";
import { RequestPasswordResetToken } from "src/app/interfaces/Index";
import { UserService } from "src/app/services/Index";

export const AdminForgotPassword = ({toast}: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const [requestPasswordResetToken, setRequestPasswordResetToken] = useState<RequestPasswordResetToken>(new RequestPasswordResetToken());
	const [requestSubmitted, setRequestSubmitted] = useState(false);

	const renderSuccess = () => {
		return (
			<div style={{backgroundColor: colours.white, display: "flex", flexDirection: "column", maxWidth: 600, padding: 40}}>
				<span style={{color: colours.secondary, fontSize: 20, marginBottom: 5}}>Thank you for your submission. Please check your email for further instructions.</span>
				<span style={{color: colours.secondary, fontSize: 20, marginBottom: 5}}>If your email hasn't arrived, please double check your spam folder.</span>
			</div>
		);
	}

	const renderForm = () => {
		return (
			<div style={{backgroundColor: colours.white, display: "flex", flexDirection: "column", justifyContent: "space-between", maxWidth: 600, padding: 40}}>
				<div>
					<span style={{color: colours.secondary, fontSize: 35, marginBottom: 10}}>Forgot Password</span>
					<InputText disabled={isLoading} id="emailAddress" onChange={(e) => setRequestPasswordResetToken({emailAddress: e.target.value})} placeholder="Email" style={{marginTop: 20, padding: "0.5rem", width: "100%"}} value={requestPasswordResetToken.emailAddress}/>
				</div>
				<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
					<Link style={{color: colours.primary, display: "block", marginTop: 10, textDecoration: "none"}} to={`/${process.env.REACT_APP_BUILD_ADMINROUTE}`}>Return to Login</Link>
					<Button iconPos="right" label="Reset Password" loading={isLoading} onClick={() => sendResetPassword()} style={{backgroundColor: colours.secondary, borderRadius: 20, borderWidth: 0, color: colours.primary, fontSize: 20, height: 50, minWidth: 150, width: "50%"}}/>
				</div>
			</div>
		);
	}

	const sendResetPassword = async () => {
		if (requestPasswordResetToken.emailAddress === undefined || requestPasswordResetToken.emailAddress === "" || requestPasswordResetToken.emailAddress!.indexOf("@") < 0) {
			if (toast.current) (toast.current as any).show({closable: false, detail: `Please ensure this is the email address you use for ${process.env.REACT_APP_BUILD_COMPANYSHORTNAME}.`, life: 5000, severity: "error", summary: "Enter your Email Address"});
			return;
		}

		setIsLoading(true);
		const res = await UserService.requestPasswordReset(requestPasswordResetToken);
		switch(res.status) {
			case 200:
				setRequestSubmitted(true);
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please check your email for further information.", life: 5000, severity: "success", summary: "Password reset sent."});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: `Please ensure you have entered the email address you use for ${process.env.REACT_APP_BUILD_COMPANYSHORTNAME}.`, life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	return (
		<div style={{backgroundImage: "url(/assets/splash.jpg)", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "1920px 1080px", height: "100vh", width: "100vw"}}>
			<div style={{alignItems: "center", backgroundColor: colours.transparent, display: "flex", height: "100vh", justifyContent: "center", width: "100vw"}}>
				<div style={{backgroundColor: "rgb(250, 50, 50)", borderRadius: 20, borderWidth: 0, display: "flex", flexDirection: "row", overflow: "hidden"}}>
					<div style={{alignItems: "center", backgroundColor: colours.secondary, display: "flex"}}>
						<img alt="Logo" src="/assets/Logo_GoldCircleWithWhite.png" style={{height: "20vw", margin: "50px 25px 50px 25px", maxHeight: 300, maxWidth: 300, width: "20vw"}}/>
					</div>
					{requestSubmitted ? renderSuccess() : renderForm()}
				</div>
			</div>
		</div>
	);
}