import { Toast } from "primereact/toast";
import { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import "./App.css";

export const App = () => {
	const toast = useRef(null);

	return (
		<BrowserRouter>
			<AppRoutes toast={toast}/>
			<Toast ref={toast}></Toast>
		</BrowserRouter>
	);
}