import { TenancyAgreementStatus } from "src/app/enums/Index";
import { Model } from "./Model";
import { TenancyAgreement } from "./TenancyAgreement";

export interface Tenant extends Model {
	documents?: string;
	emailAddress?: string;
	forwardingAddressLine1?: string;
	forwardingAddressLine2?: string;
	forwardingAddressLine3?: string;
	forwardingPostcode?: string;
	forwardingTown?: string;
	name?: string;
	phoneNumber?: string;
	tenancyAgreement?: TenancyAgreement;
	tenancyAgreementId?: number;
	tenancyAgreementStatus: TenancyAgreementStatus;
}

export class Tenant implements Tenant {
	constructor(data?: Partial<Tenant>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}