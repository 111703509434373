export const colours = {
	black: "#000000",
	buttons: {
		add: "#2BAC65",
		cancel: "#E61818",
		export: "#9C27B0",
		view: "#2196f3"
	},
	error: "#DD2222",
	primary: "#CDB974",
	secondary: "#3C3C3C",
	transparent: "rgba(0, 0, 0, 0.5)",
	white: "#FFFFFF"
};