import { faChartLineUp, faCircleCheck, faCircleExclamation, faCirclePlus, faCircleQuestion, faClose, faEnvelope, faEye, faHammerBrush, faHandshake, faHouse, faLightbulbCflOn, faMoneyBill, faPencil, faPeople, faPersonShelter, faPhone, faInfoCircle, faFile } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { colours } from "src/app/Styles";
import { EventType, ObjectStatus, PropertyType, RentChargeStatus, RentPaymentType, RentSchedule, Utility } from "src/app/enums/Index";
import { FormatDate, FormatNumber, GetPropertiesFromEnum, Timeout } from "src/app/helpers/Index";
import { EnumMap, MeterReading, Property, PropertyDocument, PropertyEvent, PropertyPerformance, RentCharge, RentPayment, TenancyAgreement } from "src/app/interfaces/Index";
import { FileService, MeterReadingService, PropertyDocumentService, PropertyEventService, PropertyService, RentChargeService, TenancyAgreementService } from "src/app/services/Index";
import { RentPaymentService } from "src/app/services/RentPaymentService";

export const AdminPropertiesId = ({toast}: any) => {
	const navigate = useNavigate();
	const params = useParams();
	const documentFileRef = useRef(null);
	const imageFileRef = useRef(null);
	const receiptFileRef = useRef(null);
	const videoFileRef = useRef(null);
	const [deletablePropertyDocument, setDeletablePropertyDocument] = useState<PropertyDocument>(new PropertyDocument());
	const [editPropertyDocumentFiles, setEditPropertyDocumentFiles] = useState<{fileName: string; lookupId: string;}[]>([]);
	const [editPropertyEventPhotos, setEditPropertyEventPhotos] = useState<{description: string; fileName: string; lookupId: string;}[]>([]);
	const [editPropertyEventVideos, setEditPropertyEventVideos] = useState<{description: string; fileName: string; lookupId: string;}[]>([]);
	const [editableProperty, setEditableProperty] = useState<Property>(new Property());
	const [editablePropertyDocument, setEditablePropertyDocument] = useState<PropertyDocument>(new PropertyDocument());
	const [editablePropertyEvent, setEditablePropertyEvent] = useState<PropertyEvent>(new PropertyEvent());
	const [isAddDocumentLoading, setIsAddDocumentLoading] = useState(false);
	const [isAddEventLoading, setIsAddEventLoading] = useState(false);
	const [isAddMeterReadingLoading, setIsAddMeterReadingLoading] = useState(false);
	const [isAddRentPaymentLoading, setIsAddRentPaymentLoading] = useState(false);
	const [isAddTenancyAgreementLoading, setIsAddTenancyAgreementLoading] = useState(false);
	const [isEditDocumentLoading, setIsEditDocumentLoading] = useState(false);
	const [isEditEventLoading, setIsEditEventLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isPropertyUpdating, setIsPropertyUpdating] = useState(false);
	const [isRemovePropertyDocumentLoading, setIsRemovePropertyDocumentLoading] = useState(false);
	const [isRemovePropertyLoading, setIsRemovePropertyLoading] = useState(false);
	const [modalPropertyEvents, setModalPropertyEvents] = useState<PropertyEvent[]>([]);
	const [newMeterReading, setNewMeterReading] = useState<MeterReading>(new MeterReading());
	const [newPropertyDocument, setNewPropertyDocument] = useState<PropertyDocument>(new PropertyDocument());
	const [newPropertyDocumentFiles, setNewPropertyDocumentFiles] = useState<{fileName: string; lookupId: string;}[]>([]);
	const [newPropertyEvent, setNewPropertyEvent] = useState<PropertyEvent>(new PropertyEvent());
	const [newPropertyEventPhotos, setNewPropertyEventPhotos] = useState<{description: string; fileName: string; lookupId: string;}[]>([]);
	const [newPropertyEventVideos, setNewPropertyEventVideos] = useState<{description: string; fileName: string; lookupId: string;}[]>([]);
	const [newRentPayment, setNewRentPayment] = useState<RentPayment>(new RentPayment());
	const [newTenancyAgreement, setNewTenancyAgreement] = useState<TenancyAgreement>(new TenancyAgreement());
	const [property, setProperty] = useState<Property>(new Property());
	const [propertyPerformance, setPropertyPerformance] = useState<PropertyPerformance>();
	const [rent, setRent] = useState<RentCharge[]>();
	const [selectedUtility, setSelectedUtility] = useState("");
	const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
	const [showAddEventModal, setShowAddEventModal] = useState(false);
	const [showAddMeterReadingModal, setShowAddMeterReadingModal] = useState(false);
	const [showAddRentPaymentModal, setShowAddRentPaymentModal] = useState(false);
	const [showAddTenancyAgreementModal, setShowAddTenancyAgreementModal] = useState(false);
	const [showDocumentsModal, setShowDocumentsModal] = useState(false);
	const [showEditPropertyDocumentModal, setShowEditPropertyDocumentModal] = useState(false);
	const [showEditPropertyModal, setShowEditPropertyModal] = useState(false);
	const [showEditPropertyEventModal, setShowEditPropertyEventModal] = useState(false);
	const [showEventsModal, setShowEventsModal] = useState(false);
	const [showRemovePropertyDocumentModal, setShowRemovePropertyDocumentModal] = useState(false);
	const [showRemovePropertyModal, setShowRemovePropertyModal] = useState(false);
	const [showUtilityModal, setShowUtilityModal] = useState(false);
	const [showViewRentPaymentsModal, setShowViewRentPaymentsModal] = useState(false);
	const [showViewTenancyModal, setShowViewTenancyModal] = useState(false);
	const [viewRentPayments, setViewRentPayments] = useState<RentPayment[]>([]);
	const paymentTypes = GetPropertiesFromEnum(RentPaymentType);
	const propertyEvents = GetPropertiesFromEnum(EventType);
	const propertyTypes = GetPropertiesFromEnum(PropertyType);
	const rentSchedule = GetPropertiesFromEnum(RentSchedule);
	const utilities = GetPropertiesFromEnum(Utility);

	useEffect(() => {
		setIsLoading(true);
		getProperty();
		getPropertyPerformance();
	}, []);

	// const changePropertyEventPhotos = async (value: string, index: number) => {
	// 	const tempArray = newPropertyEventPhotos;
	// 	const data = tempArray[index];
	// 	data.description = value;
	// 	tempArray[index] = data;
	// 	setNewPropertyEventPhotos(tempArray);
	// }

	// const changePropertyEventVideos = (value: string, index: number) => {
	// 	const tempArray = newPropertyEventVideos;
	// 	const data = tempArray[index];
	// 	data.description = value;
	// 	tempArray[index] = data;
	// 	setNewPropertyEventVideos(tempArray);
	// }

	const closeAddDocumentModal = () => {
		setShowAddDocumentModal(false);
		setNewPropertyDocument(new PropertyDocument({propertyId: newPropertyDocument.propertyId}));
	}

	const closeAddEventModal = () => {
		setShowAddEventModal(false);
		setNewPropertyEvent(new PropertyEvent());
		setNewPropertyEventPhotos([]);
		setNewPropertyEventVideos([]);
	}

	const closeAddMeterReadingModal = () => {
		setShowAddMeterReadingModal(false);
		setNewMeterReading(new MeterReading());
	}

	const closeEditDocumentModal = () => {
		setShowEditPropertyDocumentModal(false);
		setEditablePropertyDocument(new PropertyDocument());
	}

	const closeEditPropertyEventModal = () => {
		setShowEditPropertyEventModal(false);
		setEditablePropertyEvent(new PropertyEvent());
	}

	const createDocument = async () => {
		setIsAddDocumentLoading(true);
		const validFrom = new Date(newPropertyDocument.validFrom);
		validFrom.setHours(1, 0, 0, 0);
		const validTo = new Date(newPropertyDocument.validTo);
		validTo.setHours(1, 0, 0, 0);
		const fixedDocument = new PropertyDocument({...newPropertyDocument, files: JSON.stringify(newPropertyDocumentFiles), validFrom: validFrom, validTo: validTo});
		const res = await PropertyDocumentService.createSingle(fixedDocument);
		switch(res.status) {
			case 200:
				setShowAddDocumentModal(false);
				getProperty();
				getPropertyPerformance();
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "success", summary: "Document Added!"});
				setIsAddDocumentLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddDocumentLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddDocumentLoading(false);
				break;
		}
	}

	const createEvent = async () => {
		setIsAddEventLoading(true);
		const dateReported = new Date(newPropertyEvent.dateReported);
		dateReported.setHours(1, 0, 0, 0);
		let dateFixed = undefined;
		if (newPropertyEvent.dateFixed) {
			dateFixed = new Date(newPropertyEvent.dateFixed);
			dateFixed.setHours(1, 0, 0, 0);
		}
		let amountSpentOn = undefined;
		if (newPropertyEvent.amountSpentOn) {
			amountSpentOn = new Date(newPropertyEvent.amountSpentOn);
			amountSpentOn.setHours(1, 0, 0, 0);
		}
		const newPhotos = newPropertyEventPhotos;
		newPhotos.forEach((photo) => photo.description = newPropertyEvent.description || "");
		const newVideos = newPropertyEventVideos;
		newVideos.forEach((video) => video.description = newPropertyEvent.description || "");
		const fixedEvent = new PropertyEvent({...newPropertyEvent, amountSpentOn: amountSpentOn, dateFixed: dateFixed, dateReported: dateReported, photos: JSON.stringify(newPhotos), videos: JSON.stringify(newVideos)});
		const res = await PropertyEventService.createSingle(fixedEvent);
		switch(res.status) {
			case 200:
				setShowAddEventModal(false);
				getProperty();
				getPropertyPerformance();
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "success", summary: "Event Added!"});
				setIsAddEventLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddEventLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddEventLoading(false);
				break;
		}
	}

	const createMeterReading = async () => {
		setIsAddMeterReadingLoading(true);
		const readingDate = new Date(newMeterReading.readingDate);
		readingDate.setHours(1, 0, 0, 0);
		const fixedMeterReading = new MeterReading({...newMeterReading, readingDate: readingDate});
		const res = await MeterReadingService.createSingle(fixedMeterReading);
		switch(res.status) {
			case 200:
				setShowAddMeterReadingModal(false);
				getProperty();
				getPropertyPerformance();
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "success", summary: "Meter Reading Added!"});
				setIsAddMeterReadingLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddMeterReadingLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddMeterReadingLoading(false);
				break;
		}
	}

	const createRentPayment = async () => {
		setIsAddRentPaymentLoading(true);
		const datePaid = new Date(newRentPayment.datePaid);
		datePaid.setHours(1, 0, 0, 0);
		const fixedRentPayment = new RentPayment({...newRentPayment, datePaid: datePaid});
		const res = await RentPaymentService.createSingle(fixedRentPayment);
		switch(res.status) {
			case 200:
				setShowAddRentPaymentModal(false);
				getProperty();
				getPropertyPerformance();
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "success", summary: "Rent Payment Added!"});
				setIsAddRentPaymentLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddRentPaymentLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddRentPaymentLoading(false);
				break;
		}
	}

	const createTenancyAgreement = async () => {
		setIsAddTenancyAgreementLoading(true);
		const startDate = new Date(newTenancyAgreement.startDate);
		startDate.setUTCHours(1, 0, 0, 0);
		const contractValidUntil = new Date(newTenancyAgreement.contractValidUntil);
		contractValidUntil.setUTCHours(1, 0, 0, 0);
		const fixedTenancyAgreement = new TenancyAgreement({...newTenancyAgreement, contractValidUntil, startDate: startDate});
		const res = await TenancyAgreementService.createSingle(fixedTenancyAgreement);
		switch(res.status) {
			case 200:
				setShowAddTenancyAgreementModal(false);
				getProperty();
				getPropertyPerformance();
				setIsAddTenancyAgreementLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddTenancyAgreementLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddTenancyAgreementLoading(false);
				break;
		}
	}

	const documentGridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions" style={{display: "flex", flexDirection: "row"}}>
					<Button onClick={() => setEditDocumentData(rowData)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faPencil} style={{color: colours.white}}/>
					</Button>
					<Button onClick={() => setDeleteDocumentData(rowData)} style={{backgroundColor: colours.buttons.cancel, borderColor: colours.buttons.cancel, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faClose} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const eventGridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions">
					<Button onClick={() => setEditPropertyEventModalData(rowData)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const filterPropertyEvents = (data: Property) => {
		const startingDate = data.tenancyAgreements?.[0]?.startDate;
		let events = [];
		if (startingDate && data.propertyEvents) events = data.propertyEvents.filter((events) => new Date(events.createdAt).getTime() > new Date(startingDate).getTime());
		else events = data.propertyEvents || [];
		events.sort((a: PropertyEvent, b: PropertyEvent) => {
			const aDateDue = new Date(a.createdAt);
			const bDateDue = new Date(b.createdAt);
			if (aDateDue.getTime() > bDateDue.getTime()) return -1;
			else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
			else return 0;
		});
		setModalPropertyEvents(events);
	}

	const getCalendarDate = (e: any, canBeNull?: boolean) => {
		if (canBeNull && e.value === null) return;
		else if (e.value === null) return new Date();
		else return new Date(e.value);
	}

	const getProperty = async () => {
		const {id} = params;
		const res = await PropertyService.getFullDetails(Number(id) || 0);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setProperty(data);
				setEditableProperty(data);
				setNewMeterReading(new MeterReading({propertyId: data.id}));
				setNewPropertyDocument(new PropertyDocument({propertyId: data.id}));
				setNewPropertyDocumentFiles([]);
				setNewPropertyEvent(new PropertyEvent({propertyId: data.id}));
				setNewPropertyEventPhotos([]);
				setNewPropertyEventVideos([]);
				setNewTenancyAgreement(new TenancyAgreement({propertyId: data.id}));
				getRentFromAgreement(data?.tenancyAgreements?.[0]?.id);
				filterPropertyEvents(data);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const getPropertyPerformance = async () => {
		const {id} = params;
		const res = await PropertyService.getPerformanceByProperty(Number(id) || 0);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setPropertyPerformance(data);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				break;
		}
	}

	const getRentFromAgreement = async (agreementId: number) => {
		const res = await RentChargeService.getByAgreement(agreementId);
		switch(res.status) {
			case 200:
				const data = await res.json();
				data.sort((a: RentCharge, b: RentCharge) => {
					const aDateDue = new Date(a.dateDue);
					const bDateDue = new Date(b.dateDue);
					if (aDateDue.getTime() > bDateDue.getTime()) return -1;
					else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
					else return 0;
				});
				setRent(data);
				if (newRentPayment.rentChargeId !== undefined && newRentPayment.rentChargeId !== null) {
					const rentCharge = data.filter((charge: RentCharge) => charge.id === newRentPayment.rentChargeId)[0];
					setViewRentPayments(rentCharge.rentPayments);
				}
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const removeDocument = async () => {
		setIsRemovePropertyDocumentLoading(true);
		const res = await PropertyDocumentService.deleteSingleById(deletablePropertyDocument.id);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Document removed successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				if (property.propertyDocuments && (property.propertyDocuments.filter((document) => document.status !== ObjectStatus.Removed)).length -1 === 0) setShowDocumentsModal(false);
				setShowRemovePropertyDocumentModal(false);
				getProperty();
				getPropertyPerformance();
				setIsRemovePropertyDocumentLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsRemovePropertyDocumentLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsRemovePropertyDocumentLoading(false);
				break;
		}
	}

	const removeProperty = async () => {
		setIsRemovePropertyLoading(true);
		const res = await PropertyService.deleteSingleById(property.id);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Property removed successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				navigate("/admin/main/properties");
				setIsRemovePropertyLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsRemovePropertyLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsRemovePropertyLoading(false);
				break;
		}
	}

	const removePropertyDocumentFilesAdd = async (index: number) => {
		setIsAddEventLoading(true);
		const tempArray = newPropertyDocumentFiles;
		tempArray.splice(index, 1);
		setNewPropertyDocumentFiles(tempArray);
		await Timeout(100);
		setIsAddEventLoading(false);
	}

	const removePropertyEventPhotosAdd = async (index: number) => {
		setIsAddEventLoading(true);
		const tempArray = newPropertyEventPhotos;
		tempArray.splice(index, 1);
		setNewPropertyEventPhotos(tempArray);
		await Timeout(100);
		setIsAddEventLoading(false);
	}

	const removePropertyEventPhotosEdit = async (index: number) => {
		setIsEditEventLoading(true);
		const tempArray = newPropertyEventPhotos;
		tempArray.splice(index, 1);
		setEditPropertyEventPhotos(tempArray);
		await Timeout(100);
		setIsEditEventLoading(false);
	}

	const removePropertyEventVideosAdd = async (index: number) => {
		setIsAddEventLoading(true);
		const tempArray = newPropertyEventVideos;
		tempArray.splice(index, 1);
		setNewPropertyEventVideos(tempArray);
		await Timeout(100);
		setIsAddEventLoading(false);
	}

	const removePropertyEventVideosEdit = async (index: number) => {
		setIsEditEventLoading(true);
		const tempArray = newPropertyEventVideos;
		tempArray.splice(index, 1);
		setEditPropertyEventVideos(tempArray);
		await Timeout(100);
		setIsEditEventLoading(false);
	}

	const renderAddDocumentModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => closeAddDocumentModal()}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Document" onClick={() => createDocument()}></Button>
			</>
		);
	}

	const renderAddEventModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => closeAddEventModal()}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Event" onClick={() => createEvent()}></Button>
			</>
		);
	}

	const renderAddMeterReadingModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => closeAddMeterReadingModal()}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Meter Reading" onClick={() => createMeterReading()}></Button>
			</>
		);
	}

	const renderAddRentPaymentModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowAddRentPaymentModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Rent Payment" onClick={() => createRentPayment()}></Button>
			</>
		);
	}

	const renderAddTenancyAgreementModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowAddTenancyAgreementModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Tenancy Agreement" onClick={() => createTenancyAgreement()}></Button>
			</>
		);
	}

	const renderEditDocumentModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowEditPropertyDocumentModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => savePropertyDocument()}></Button>
			</>
		);
	}

	const renderEditPropertyEventModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => closeEditPropertyEventModal()}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => savePropertyEvent()}></Button>
			</>
		);
	}

	const renderEditPropertyModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowEditPropertyModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => saveProperty()}></Button>
			</>
		);
	}

	const renderEditUtilityModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowUtilityModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => saveProperty()}></Button>
			</>
		);
	}

	const renderRemovePropertyDocumentModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowRemovePropertyDocumentModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Confirm" onClick={() => removeDocument()}></Button>
			</>
		);
	}

	const renderRemovePropertyModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowRemovePropertyModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Confirm" onClick={() => removeProperty()}></Button>
			</>
		);
	}

	const renderViewRentPaymentsModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-plus" label="Add Rent Payment" onClick={() => viewAddRentPaymentModal()}></Button>
			</>
		);
	}

	const renderViewTenancyModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-eye" label="View Details" onClick={() => navigate(`/admin/main/tenancy-agreement/${property.tenancyAgreements?.[0]?.id}`)}></Button>
			</>
		);
	}

	const rentGridButtons = (rowData: any) => {
		return (
			<div className="actions">
				<Button onClick={() => viewRentPaymentsModal(rowData.rentPayments || [], rowData.id)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
					<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
				</Button>
			</div>
		);
	}

	const saveProperty = async () => {
		setIsPropertyUpdating(true);
		const res = await PropertyService.updateSingleById(editableProperty.id, editableProperty);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				getProperty();
				getPropertyPerformance();
				setIsPropertyUpdating(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsPropertyUpdating(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsPropertyUpdating(false);
				break;
		}
	}

	const savePropertyDocument = async () => {
		setIsEditDocumentLoading(true);
		const validFrom = new Date(editablePropertyDocument.validFrom);
		validFrom.setHours(1, 0, 0, 0);
		const validTo = new Date(editablePropertyDocument.validTo);
		validTo.setHours(1, 0, 0, 0);
		const fixedDocument = new PropertyDocument({...editablePropertyDocument, files: JSON.stringify(editPropertyDocumentFiles), validFrom: validFrom, validTo: validTo});
		const res = await PropertyDocumentService.updateSingleById(fixedDocument.id, fixedDocument);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				getProperty();
				getPropertyPerformance();
				closeEditDocumentModal();
				setIsEditDocumentLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsEditDocumentLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsEditDocumentLoading(false);
				break;
		}
	}

	const savePropertyEvent = async () => {
		setIsEditEventLoading(true);
		const dateReported = new Date(editablePropertyEvent.dateReported);
		dateReported.setHours(1, 0, 0, 0);
		let dateFixed = undefined;
		if (editablePropertyEvent.dateFixed) {
			dateFixed = new Date(editablePropertyEvent.dateFixed);
			dateFixed.setHours(1, 0, 0, 0);
		}
		let amountSpentOn = undefined;
		if (editablePropertyEvent.amountSpentOn) {
			amountSpentOn = new Date(editablePropertyEvent.amountSpentOn);
			amountSpentOn.setHours(1, 0, 0, 0);
		}
		const newPhotos = editPropertyEventPhotos;
		newPhotos.forEach((photo) => photo.description = editablePropertyEvent.description || "");
		const newVideos = editPropertyEventVideos;
		newVideos.forEach((video) => video.description = editablePropertyEvent.description || "");
		const fixedEvent = new PropertyEvent({...editablePropertyEvent, amountSpentOn: amountSpentOn, dateFixed: dateFixed, dateReported: dateReported, photos: JSON.stringify(newPhotos), videos: JSON.stringify(newVideos)});
		const res = await PropertyEventService.updateSingleById(fixedEvent.id, fixedEvent);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				closeEditPropertyEventModal();
				if (property.propertyDocuments && (property.propertyDocuments.filter((document) => document.status !== ObjectStatus.Removed)).length -1 === 0) setShowDocumentsModal(false);
				getProperty();
				setIsEditEventLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsEditEventLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsEditEventLoading(false);
				break;
		}
	}

	const setDeleteDocumentData = (data: any) => {
		if (data.dateReported) data.dateReported = new Date(data.dateReported);
		if (data.dateFixed) data.dateFixed = new Date(data.dateFixed);
		if (data.amountSpentOn) data.amountSpentOn = new Date(data.amountSpentOn);
		setDeletablePropertyDocument(data);
		setShowRemovePropertyDocumentModal(true);
	}

	const setEditDocumentData = (data: any) => {
		if (data.dateReported) data.dateReported = new Date(data.dateReported);
		if (data.dateFixed) data.dateFixed = new Date(data.dateFixed);
		if (data.amountSpentOn) data.amountSpentOn = new Date(data.amountSpentOn);
		setEditablePropertyDocument(data);
		setShowEditPropertyDocumentModal(true);
	}

	const setEditPropertyEventModalData = (data: any) => {
		if (data.dateReported) data.dateReported = new Date(data.dateReported);
		if (data.dateFixed) data.dateFixed = new Date(data.dateFixed);
		if (data.amountSpentOn) data.amountSpentOn = new Date(data.amountSpentOn);
		setEditablePropertyEvent(data);
		setEditPropertyEventPhotos(JSON.parse(data.photos || "[]"));
		setEditPropertyEventVideos(JSON.parse(data.videos || "[]"));
		setShowEditPropertyEventModal(true);
	}

	// Returns true if there is no valid/active tenancy agreement
	const tenancyAgreementCheck = () => {
		if (!(property.id > 0)) return false;
		else if (property.tenancyAgreements === undefined || property.tenancyAgreements === null || (property.tenancyAgreements && property.tenancyAgreements.length === 0)) return true;
		else if (property.tenancyAgreements && property.tenancyAgreements.length > 0) {
			let hasActiveAgreement = false;
			property.tenancyAgreements.forEach((agreement) => {
				const startTime = new Date(agreement.startDate).getTime();
				const now = new Date().getTime();
				if (now > startTime) {
					const endTime = new Date(agreement.contractValidUntil).getTime();
					if (now < endTime) hasActiveAgreement = true;
				} else hasActiveAgreement = true;
			});
			return !hasActiveAgreement;
		} else return false;
	}

	const tenantGridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions">
					<Button onClick={() => navigate(`/admin/main/tenants/${rowData.id}`)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const uploadError = () => {
		if (toast.current) (toast.current as any).show({closable: false, detail: "Please ensure you are uploading an image file no larger than 1MB.", life: 5000, severity: "error", summary: "Upload Failed"});
	}

	const uploadDocumentAdd = async (data: any) => {
		setIsAddDocumentLoading(true);
		const uploadData = new FormData();
		uploadData.append("containerName", "uploads");
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				const newDocument = {fileName: data[0].name, lookupId: data[0].lookupId};
				setNewPropertyDocumentFiles([...newPropertyDocumentFiles, newDocument]);
				if (documentFileRef.current) (documentFileRef.current as any).clear();
				setIsAddDocumentLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddDocumentLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddDocumentLoading(false);
				break;
		}
	}

	const uploadDocumentEdit = async (data: any) => {
		setIsEditDocumentLoading(true);
		const uploadData = new FormData();
		uploadData.append("containerName", "uploads");
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				const newDocument = {fileName: data[0].name, lookupId: data[0].lookupId};
				setEditPropertyDocumentFiles([...editPropertyDocumentFiles, newDocument]);
				if (imageFileRef.current) (imageFileRef.current as any).clear();
				setIsEditDocumentLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsEditDocumentLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsEditDocumentLoading(false);
				break;
		}
	}

	const uploadImageAdd = async (data: any) => {
		setIsAddEventLoading(true);
		const uploadData = new FormData();
		uploadData.append("containerName", "uploads");
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				const newPhoto = {description: "", fileName: data[0].name, lookupId: data[0].lookupId};
				setNewPropertyEventPhotos([...newPropertyEventPhotos, newPhoto]);
				if (imageFileRef.current) (imageFileRef.current as any).clear();
				setIsAddEventLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddEventLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddEventLoading(false);
				break;
		}
	}

	const uploadImageEdit = async (data: any) => {
		setIsEditEventLoading(true);
		const uploadData = new FormData();
		uploadData.append("containerName", "uploads");
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				const newPhoto = {description: "", fileName: data[0].name, lookupId: data[0].lookupId};
				setEditPropertyEventPhotos([...editPropertyEventPhotos, newPhoto]);
				if (imageFileRef.current) (imageFileRef.current as any).clear();
				setIsEditEventLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsEditEventLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsEditEventLoading(false);
				break;
		}
	}

	const uploadReceiptAdd = async (data: any) => {
		setIsAddRentPaymentLoading(true);
		const containerName = "uploads";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setNewRentPayment({...newRentPayment, receiptImageUrl: `${process.env.REACT_APP_SERVER_STORAGEURL}/${containerName}/${data[0].location}`});
				if (receiptFileRef.current) (receiptFileRef.current as any).clear();
				setIsAddRentPaymentLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddRentPaymentLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddRentPaymentLoading(false);
				break;
		}
	}

	const uploadVideoAdd = async (data: any) => {
		setIsAddEventLoading(true);
		const uploadData = new FormData();
		uploadData.append("containerName", "uploads");
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				const newVideo = {description: "", fileName: data[0].name, lookupId: data[0].lookupId};
				setNewPropertyEventVideos([...newPropertyEventVideos, newVideo]);
				if (videoFileRef.current) (videoFileRef.current as any).clear();
				setIsAddEventLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddEventLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddEventLoading(false);
				break;
		}
	}

	const uploadVideoEdit = async (data: any) => {
		setIsEditEventLoading(true);
		const uploadData = new FormData();
		uploadData.append("containerName", "uploads");
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				const newVideo = {description: "", fileName: data[0].name, lookupId: data[0].lookupId};
				setEditPropertyEventVideos([...editPropertyEventVideos, newVideo]);
				if (videoFileRef.current) (videoFileRef.current as any).clear();
				setIsEditEventLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsEditEventLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsEditEventLoading(false);
				break;
		}
	}

	const viewAddRentPaymentModal = () => {
		setShowAddRentPaymentModal(true);
		setNewRentPayment(new RentPayment({rentChargeId: newRentPayment.rentChargeId}));
	}

	const viewRentPaymentsModal = (rentPayments: RentPayment[], rentChargeId: number) => {
		setShowViewRentPaymentsModal(true);
		setNewRentPayment(new RentPayment({rentChargeId: rentChargeId}));
		setViewRentPayments(rentPayments);
	}

	const viewUtilityModal = (utility: EnumMap) => {
		setShowUtilityModal(true);
		setSelectedUtility(utility.name);
	}

	return (
		<>
			<Row>
				<Col style={{marginBottom: 10}}>
					{tenancyAgreementCheck() &&
						<Button onClick={() => setShowRemovePropertyModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faClose} style={{fontSize: 15, marginRight: 10, width: 15}}/>Remove Property</Button>
					}
					<Button onClick={() => setShowEditPropertyModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faPencil} style={{fontSize: 15, marginRight: 10, width: 15}}/>Edit Property</Button>
					{tenancyAgreementCheck() &&
						<Button onClick={() => setShowAddTenancyAgreementModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Add Tenancy Agreement</Button>
					}
					<Button onClick={() => setShowAddMeterReadingModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Meter Reading</Button>
					<Button onClick={() => setShowAddEventModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Event</Button>
					<Button onClick={() => setShowAddDocumentModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Document</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="card" style={{marginBottom: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<span style={{fontSize: 25, fontWeight: "bold"}}>{`${property.addressLine1}, ${property.town}, ${property.postcode}`}</span>
						<span>{`${PropertyType[property.propertyType || 0]} / EPC Rating - ${property.epcRating}`}</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={5}>
					<div className="card" style={{paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHandshake} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Tenancy Details</span>
						</div>
						<span>Agreement Number: {property?.tenancyAgreements?.[0]?.id}</span>
						<span>Primary Contact: {property?.tenancyAgreements?.[0]?.primaryContactTenant?.name}</span>
						<span>Contract Start: {FormatDate(property?.tenancyAgreements?.[0]?.startDate)}</span>
						<span>Valid Until: {FormatDate(property?.tenancyAgreements?.[0]?.contractValidUntil)}</span>
						<span>Next Charge: {FormatDate(property?.tenancyAgreements?.[0]?.generateRentChargeOn)}</span>
						{tenancyAgreementCheck() === false &&
							<div style={{marginTop: 10}}>
								<span className="span-link" onClick={() => setShowViewTenancyModal(true)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faEye} style={{fontSize: 20, marginRight: 5, width: 20}}/> View Agreement</span>
							</div>
						}
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faPersonShelter} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Landlord Details</span>
						</div>
						<span style={{marginBottom: 10}}>{property?.landlord?.name}</span>
						<span>{property?.landlord?.addressLine1}</span>
						<span>{property?.landlord?.addressLine2}</span>
						<span>{property?.landlord?.addressLine3}</span>
						<span>{property?.landlord?.town}</span>
						<span>{property?.landlord?.postcode}</span>
						<div style={{marginTop: 10}}>
							<span className="span-link" onClick={() => navigate(`/admin/main/landlords/${property?.landlord?.id}`)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faHouse} style={{fontSize: 20, marginRight: 5, width: 20}}/> View All Properties</span>
						</div>
						<div style={{marginTop: 10}}>
							<a className="span-link" href={`mailto:${property?.landlord?.emailAddress}`} style={{color: colours.black, cursor: "pointer", textDecoration: "none"}}>
								<FontAwesomeIcon icon={faEnvelope} style={{fontSize: 20, marginRight: 5}}/> Email Landlord
							</a>
						</div>
						<div style={{marginTop: 10}}>
							<a className="span-link" href={`tel:${property?.landlord?.phoneNumber}`} style={{color: colours.black, cursor: "pointer", marginRight: 20, textDecoration: "none"}}>
								<FontAwesomeIcon icon={faPhone} style={{fontSize: 20, marginRight: 5}}/> Call Landlord
							</a>
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faPeople} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Tenants</span>
							<DataTable scrollable scrollHeight="flex" value={property?.tenancyAgreements?.[0]?.tenants}>
								<Column field="name" header="Name" sortable></Column>
								<Column body={tenantGridButtons} style={{width: 50}}></Column>
							</DataTable>
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHammerBrush} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Events</span>
						</div>
						<span>Total Events: {modalPropertyEvents.length}</span>
						{modalPropertyEvents.length > 0 &&
							<div style={{marginTop: 10}}>
								<span className="span-link" onClick={() => setShowEventsModal(true)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faEye} style={{fontSize: 20, marginRight: 5, width: 20}}/> View Events</span>
							</div>
						}
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faFile} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Documents</span>
						</div>
						<span>Total Documents: {property.propertyDocuments ? (property.propertyDocuments.filter((document) => document.status !== ObjectStatus.Removed)).length : 0}</span>
						{property.propertyDocuments && (property.propertyDocuments.filter((document) => document.status !== ObjectStatus.Removed)).length > 0 &&
							<div style={{marginTop: 10}}>
								<span className="span-link" onClick={() => setShowDocumentsModal(true)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faEye} style={{fontSize: 20, marginRight: 5, width: 20}}/> View Documents</span>
							</div>
						}
					</div>
				</Col>
				<Col xs={7}>
					<div className="card" style={{paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faChartLineUp} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Property Performance</span>
						</div>
						<div style={{display: "flex"}}>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.totalRent : 0, true)}</span>
								<span>Total Rent</span>
							</div>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.monthlyRent : 0, true)}</span>
								<span>Monthly Rent</span>
							</div>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.serviceCharges : 0, true)}</span>
								<span>Service Charges</span>
							</div>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.maintenance : 0, true)}</span>
								<span>Maintenance</span>
							</div>
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faLightbulbCflOn} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Meter Readings</span>
						</div>
						<div style={{display: "flex"}}>
							{utilities.map((utility) => {
								let reading = property?.meterReadings?.filter((reading: MeterReading) => reading.utility === utility.id).sort((a: MeterReading, b: MeterReading) => {
									const aDate = new Date(a.readingDate);
									const bDate = new Date(b.readingDate);
									if (aDate.getTime() > bDate.getTime()) return -1;
									else if (aDate.getTime() < bDate.getTime()) return 1;
									else return 0;
								});
								return (
									<div key={`ap${utility.id}`} style={{display: "flex", flex: 1, flexDirection: "column"}}>
										<span style={{fontSize: 20, fontWeight: "bold"}}>{reading && reading[0] && reading[0].readingValue ? reading[0].readingValue : "N/A"}</span>
										<span onClick={() => viewUtilityModal(utility)} style={{cursor: "pointer"}}>{utility.name} <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: 15, width: 15}}/></span>
									</div>
								);
							})}
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faMoneyBill} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Rent Book</span>
							<DataTable scrollable scrollHeight="flex" value={rent}>
								<Column body={(rowData) => FormatDate((rowData.dateDue))} field="dateDue" header="Due Date" sortable style={{width: 125}}></Column>
								<Column field="rentAmount" header="Rent Amount" sortable style={{width: 155}}></Column>
								<Column field="rentPayments[0].paymentValue" header="Rent Paid" sortable style={{width: 125}} body={(rowData) => {
									const totalPaid = rowData.rentPayments.length > 0 ? rowData.rentPayments.map((payment: RentPayment) => payment.paymentValue).reduce((a: number, b: number) => a + b) : 0;
									return FormatNumber(totalPaid);
								}}></Column>
								<Column body={(rowData) => FormatDate((rowData.datePaid))} field="datePaid" header="Paid On" sortable style={{width: 125}}></Column>
								<Column field="tenancyAgreement.primaryContactTenant" header="Tenant" sortable></Column>
								<Column field="chargeStatus" header="Status" sortable style={{width: 120}} body={(rowData) => {
									switch (rowData.chargeStatus as RentChargeStatus) {
										case RentChargeStatus.Paid:
											return (<span style={{color: "#7FD764"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Paid</span>);
										case RentChargeStatus.Due:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Due</span>);
										case RentChargeStatus.Late:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Late</span>);
										case RentChargeStatus.OverDue:
											return (<span style={{color: "#F0552A"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Overdue</span>);
										case RentChargeStatus.NotDue:
											return (<span><FontAwesomeIcon icon={faCircleQuestion} style={{fontSize: 15, width: 15}}/> Not Due</span>);
									}
								}}></Column>
								<Column body={rentGridButtons} style={{width: 50}}></Column>
							</DataTable>
						</div>
					</div>
				</Col>
			</Row>
			<Dialog className="p-fluid add-document" draggable={false} footer={() => renderAddDocumentModalFooter()} header="Add Document" modal onHide={() => closeAddDocumentModal()} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddDocumentModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="documentType">Document Type <span style={{color:"red"}}>*</span></label>
							<InputText id="documentType" onChange={(e) => setNewPropertyDocument({...newPropertyDocument, documentType: e.target.value})} required value={newPropertyDocument.documentType}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="validFrom">Valid From <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="validFrom" onChange={(e) => setNewPropertyDocument({...newPropertyDocument, validFrom: getCalendarDate(e)!})} required showIcon value={newPropertyDocument.validFrom}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="validTo">Valid To <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="validTo" minDate={new Date()} onChange={(e) => setNewPropertyDocument({...newPropertyDocument, validTo: getCalendarDate(e)!})} required showIcon value={newPropertyDocument.validTo}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="files">Files</label>
							<FileUpload accept="application/pdf" auto customUpload maxFileSize={1000000} mode="basic" name="files" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={documentFileRef} uploadHandler={(data) => uploadDocumentAdd(data)}></FileUpload>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						{newPropertyDocumentFiles.map((data, i) => {
							return (
								<div key={`npep-${i}`} style={{marginTop: 10}}>
									<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
										<div style={{display: "flex"}}>{data.fileName}</div>
										<FontAwesomeIcon icon={faClose} onClick={() => removePropertyDocumentFilesAdd(i)} style={{cursor: "pointer", fontSize: 24}}/>
									</div>
								</div>
							);
						})}
					</Col>
				</Row>
				{isAddDocumentLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid add-event" draggable={false} footer={() => renderAddEventModalFooter()} header="Add Event" modal onHide={() => closeAddEventModal()} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddEventModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="eventType">Type <span style={{color:"red"}}>*</span></label>
							<Dropdown id="eventType" onChange={(e) => setNewPropertyEvent({...newPropertyEvent, eventType: e.value})} options={propertyEvents} optionLabel="name" optionValue="id" required style={{width: "100%"}} value={newPropertyEvent.eventType}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="description">Description</label>
							<InputTextarea id="description" onChange={(e) => setNewPropertyEvent({...newPropertyEvent, description: e.target.value})} rows={3} value={newPropertyEvent.description}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="readingValue">Date Reported <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="dateReported" maxDate={new Date()} onChange={(e) => setNewPropertyEvent({...newPropertyEvent, dateReported: getCalendarDate(e)!})} required showIcon value={newPropertyEvent.dateReported}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="dateFixed">Date Fixed</label>
							<Calendar dateFormat="dd/mm/yy" id="dateFixed" maxDate={new Date()} onChange={(e) => setNewPropertyEvent({...newPropertyEvent, dateFixed: getCalendarDate(e, true)})} showIcon value={newPropertyEvent.dateFixed}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="amountSpent">Amount Spent</label>
							<InputNumber id="amountSpent" onChange={(e) => setNewPropertyEvent({...newPropertyEvent, amountSpent: e.value || undefined})} value={newPropertyEvent.amountSpent}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="amountSpentOn">Amount Spent On</label>
							<Calendar dateFormat="dd/mm/yy" id="amountSpentOn" maxDate={new Date()} onChange={(e) => setNewPropertyEvent({...newPropertyEvent, amountSpentOn: getCalendarDate(e, true)})} showIcon value={newPropertyEvent.amountSpentOn}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="descriptionOfSpending">Description Of Spending</label>
							<InputTextarea id="descriptionOfSpending" onChange={(e) => setNewPropertyEvent({...newPropertyEvent, descriptionOfSpending: e.target.value})} rows={1} value={newPropertyEvent.descriptionOfSpending}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="readingValue">Photos</label>
							<FileUpload accept="image/*" auto customUpload maxFileSize={1000000} mode="basic" name="photos" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={imageFileRef} uploadHandler={(data) => uploadImageAdd(data)}></FileUpload>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="dateFixed">Videos</label>
							<FileUpload accept="video/*" auto customUpload maxFileSize={1000000} mode="basic" name="videos" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={videoFileRef} uploadHandler={(data) => uploadVideoAdd(data)}></FileUpload>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						{newPropertyEventPhotos.map((data, i) => {
							return (
								<div key={`npep-${i}`} style={{marginTop: 10}}>
									{/* <div>
										<InputText onChange={(e) => changePropertyEventPhotos(e.target.value, i)} required value={data.description}/>
									</div> */}
									<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
										<div style={{display: "flex"}}>{data.fileName}</div>
										<FontAwesomeIcon icon={faClose} onClick={() => removePropertyEventPhotosAdd(i)} style={{cursor: "pointer", fontSize: 24}}/>
									</div>
								</div>
							);
						})}
					</Col>
					<Col>
						{newPropertyEventVideos.map((data, i) => {
							return (
								<div key={`npev-${i}`} style={{marginTop: 10}}>
									{/* <div>
										<InputText onChange={(e) => changePropertyEventVideos(e.target.value, i)} required value={data.description}/>
									</div> */}
									<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
										<div style={{display: "flex"}}>{data.fileName}</div>
										<FontAwesomeIcon icon={faClose} onClick={() => removePropertyEventVideosAdd(i)} style={{cursor: "pointer", fontSize: 24}}/>
									</div>
								</div>
							);
						})}
					</Col>
				</Row>
				{isAddEventLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid add-meter-reading" draggable={false} footer={() => renderAddMeterReadingModalFooter()} header="Add Meter Reading" modal onHide={() => closeAddMeterReadingModal()} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddMeterReadingModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="utility">Utility <span style={{color:"red"}}>*</span></label>
							<Dropdown id="utility" onChange={(e) => setNewMeterReading({...newMeterReading, utility: e.value})} options={utilities} optionLabel="name" optionValue="id" required style={{width: "100%"}} value={newMeterReading.utility}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="readingValue">Reading Value <span style={{color:"red"}}>*</span></label>
							<InputNumber id="readingValue" onChange={(e) => setNewMeterReading({...newMeterReading, readingValue: e.value || 0})} required value={newMeterReading.readingValue}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="readingDate">Reading Date <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="readingDate" maxDate={new Date()} onChange={(e) => setNewMeterReading({...newMeterReading, readingDate: getCalendarDate(e)!})} required showIcon value={newMeterReading.readingDate}/>
						</div>
					</Col>
				</Row>
				{isAddMeterReadingLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid add-rent-payment" draggable={false} footer={() => renderAddRentPaymentModalFooter()} header="Add Rent Payment" modal onHide={() => setShowAddRentPaymentModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddRentPaymentModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="paymentValue">Payment Amount <span style={{color:"red"}}>*</span></label>
							<InputNumber id="paymentValue" onChange={(e) => setNewRentPayment({...newRentPayment, paymentValue: e.value || 0})} required value={newRentPayment.paymentValue}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="paymentType">Payment Type <span style={{color:"red"}}>*</span></label>
							<Dropdown id="paymentType" onChange={(e) => setNewRentPayment({...newRentPayment, paymentType: e.value})} options={paymentTypes} optionLabel="name" optionValue="id" required style={{width: "100%"}} value={newRentPayment.paymentType}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="receipt">Receipt</label>
							<FileUpload accept="image/*" auto customUpload maxFileSize={1000000} mode="basic" name="receipt" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={receiptFileRef} uploadHandler={(data) => uploadReceiptAdd(data)}></FileUpload>
							{newRentPayment.receiptImageUrl !== null && newRentPayment.receiptImageUrl !== undefined &&
								<div style={{marginTop: 10}}>
									<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
										<div style={{display: "flex"}}>Receipt Image</div>
										<FontAwesomeIcon icon={faClose} onClick={() => setNewRentPayment({...newRentPayment, receiptImageUrl: undefined})} style={{cursor: "pointer", fontSize: 24}}/>
									</div>
								</div>
							}
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="datePaid">Date Paid</label>
							<Calendar dateFormat="dd/mm/yy" id="datePaid" maxDate={new Date()} onChange={(e) => setNewRentPayment({...newRentPayment, datePaid: getCalendarDate(e)!})} required showIcon value={newRentPayment.datePaid}/>
						</div>
					</Col>
				</Row>
				{isAddRentPaymentLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid add-tenancy-agreement" draggable={false} footer={() => renderAddTenancyAgreementModalFooter()} header="Add Tenancy Agreement" modal onHide={() => setShowAddTenancyAgreementModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddTenancyAgreementModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="rentPrice">Rent Amount <span style={{color:"red"}}>*</span></label>
							<InputNumber id="rentPrice" onChange={(e) => setNewTenancyAgreement({...newTenancyAgreement, rentPrice: e.value || 0})} required value={newTenancyAgreement.rentPrice}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="rentSchedule">Rent Schedule <span style={{color:"red"}}>*</span></label>
							<Dropdown id="rentSchedule" onChange={(e) => setNewTenancyAgreement({...newTenancyAgreement, rentSchedule: e.value})} options={rentSchedule} optionLabel="name" optionValue="id" required style={{width: "100%"}} value={newTenancyAgreement.rentSchedule}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="startDate">Start Date <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="startDate" minDate={new Date()} onChange={(e) => setNewTenancyAgreement({...newTenancyAgreement, startDate: getCalendarDate(e)!})} required showIcon value={newTenancyAgreement.startDate}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="contractValidUntil">Contract Valid Until <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="contractValidUntil" minDate={new Date()} onChange={(e) => setNewTenancyAgreement({...newTenancyAgreement, contractValidUntil: getCalendarDate(e)!})} required showIcon value={newTenancyAgreement.contractValidUntil}/>
						</div>
					</Col>
				</Row>
				{isAddTenancyAgreementLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid edit-document" draggable={false} footer={() => renderEditDocumentModalFooter()} header="Edit Document" modal onHide={() => closeEditDocumentModal()} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEditPropertyDocumentModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="documentType">Document Type <span style={{color:"red"}}>*</span></label>
							<InputText id="documentType" onChange={(e) => setEditablePropertyDocument({...editablePropertyDocument, documentType: e.target.value})} required value={editablePropertyDocument.documentType}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="validFrom">Valid From <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="validFrom" onChange={(e) => setEditablePropertyDocument({...editablePropertyDocument, validFrom: getCalendarDate(e)!})} required showIcon value={new Date(editablePropertyDocument.validFrom)}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="validTo">Valid To <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="validTo" minDate={new Date()} onChange={(e) => setEditablePropertyDocument({...editablePropertyDocument, validTo: getCalendarDate(e)!})} required showIcon value={new Date(editablePropertyDocument.validTo)}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="files">Files</label>
							<FileUpload accept="application/pdf" auto customUpload maxFileSize={1000000} mode="basic" name="files" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={documentFileRef} uploadHandler={(data) => uploadDocumentAdd(data)}></FileUpload>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						{editPropertyDocumentFiles.map((data, i) => {
							return (
								<div key={`npep-${i}`} style={{marginTop: 10}}>
									<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
										<div style={{display: "flex"}}>{data.fileName}</div>
										<FontAwesomeIcon icon={faClose} onClick={() => removePropertyDocumentFilesAdd(i)} style={{cursor: "pointer", fontSize: 24}}/>
									</div>
								</div>
							);
						})}
					</Col>
				</Row>
				{isEditDocumentLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid edit-event" draggable={false} footer={() => renderEditPropertyEventModalFooter()} header="Edit Event" modal onHide={() => closeEditPropertyEventModal()} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEditPropertyEventModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="eventType">Type <span style={{color:"red"}}>*</span></label>
							<Dropdown id="eventType" onChange={(e) => setEditablePropertyEvent({...editablePropertyEvent, eventType: e.value})} options={propertyEvents} optionLabel="name" optionValue="id" required style={{width: "100%"}} value={editablePropertyEvent.eventType}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="description">Description</label>
							<InputTextarea id="description" onChange={(e) => setEditablePropertyEvent({...editablePropertyEvent, description: e.target.value})} rows={3} value={editablePropertyEvent.description}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="readingValue">Date Reported <span style={{color:"red"}}>*</span></label>
							<Calendar dateFormat="dd/mm/yy" id="dateReported" maxDate={new Date()} onChange={(e) => setEditablePropertyEvent({...editablePropertyEvent, dateReported: getCalendarDate(e)!})} required showIcon value={editablePropertyEvent.dateReported}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="dateFixed">Date Fixed</label>
							<Calendar dateFormat="dd/mm/yy" id="dateFixed" maxDate={new Date()} onChange={(e) => setEditablePropertyEvent({...editablePropertyEvent, dateFixed: getCalendarDate(e, true)})} showIcon value={editablePropertyEvent.dateFixed}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="amountSpent">Amount Spent</label>
							<InputNumber id="amountSpent" onChange={(e) => setEditablePropertyEvent({...editablePropertyEvent, amountSpent: e.value || undefined})} value={editablePropertyEvent.amountSpent}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="amountSpentOn">Amount Spent On</label>
							<Calendar dateFormat="dd/mm/yy" id="amountSpentOn" maxDate={new Date()} onChange={(e) => setEditablePropertyEvent({...editablePropertyEvent, amountSpentOn: getCalendarDate(e, true)})} showIcon value={editablePropertyEvent.amountSpentOn}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="descriptionOfSpending">Description Of Spending</label>
							<InputTextarea id="descriptionOfSpending" onChange={(e) => setEditablePropertyEvent({...editablePropertyEvent, descriptionOfSpending: e.target.value})} rows={1} value={editablePropertyEvent.descriptionOfSpending}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="readingValue">Photos</label>
							<FileUpload accept="image/*" auto customUpload maxFileSize={1000000} mode="basic" multiple name="photos" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={imageFileRef} uploadHandler={(data) => uploadImageEdit(data)}></FileUpload>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="dateFixed">Videos</label>
							<FileUpload accept="video/*" auto customUpload maxFileSize={1000000} mode="basic" multiple name="videos" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={videoFileRef} uploadHandler={(data) => uploadVideoEdit(data)}></FileUpload>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						{editPropertyEventPhotos.map((data, i) => {
							return (
								<div key={`npep-${i}`} style={{marginTop: 10}}>
									{/* <div>
										<InputText onChange={(e) => changePropertyEventPhotos(e.target.value, i)} required value={data.description}/>
									</div> */}
									<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
										<div style={{display: "flex"}}>{data.fileName}</div>
										<FontAwesomeIcon icon={faClose} onClick={() => removePropertyEventPhotosEdit(i)} style={{cursor: "pointer", fontSize: 24}}/>
									</div>
								</div>
							);
						})}
					</Col>
					<Col>
						{editPropertyEventVideos.map((data, i) => {
							return (
								<div key={`npev-${i}`} style={{marginTop: 10}}>
									{/* <div>
										<InputText onChange={(e) => changePropertyEventVideos(e.target.value, i)} required value={data.description}/>
									</div> */}
									<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
										<div style={{display: "flex"}}>{data.fileName}</div>
										<FontAwesomeIcon icon={faClose} onClick={() => removePropertyEventVideosEdit(i)} style={{cursor: "pointer", fontSize: 24}}/>
									</div>
								</div>
							);
						})}
					</Col>
				</Row>
				{isEditEventLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid edit-property" draggable={false} footer={() => renderEditPropertyModalFooter()} header="Edit Property" modal onHide={() => setShowEditPropertyModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEditPropertyModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine1">Address Line 1</label>
							<InputText id="addressLine1" onChange={(e) => setEditableProperty({...editableProperty, addressLine1: e.target.value})} value={editableProperty.addressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="addressLine2">Address Line 2</label>
							<InputText id="addressLine2" onChange={(e) => setEditableProperty({...editableProperty, addressLine2: e.target.value})} value={editableProperty.addressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine3">Address Line 3</label>
							<InputText id="addressLine3" onChange={(e) => setEditableProperty({...editableProperty, addressLine3: e.target.value})} value={editableProperty.addressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="town">Town</label>
							<InputText id="town" onChange={(e) => setEditableProperty({...editableProperty, town: e.target.value})} value={editableProperty.town}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="postcode">Postcode</label>
							<InputText id="postcode" onChange={(e) => setEditableProperty({...editableProperty, postcode: e.target.value})} value={property.postcode}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="propertyType">Property Type <span style={{color:"red"}}>*</span></label>
							<Dropdown id="propertyType" onChange={(e) => setEditableProperty({...editableProperty, propertyType: e.value})} options={propertyTypes} optionLabel="name" optionValue="id" required style={{ width: "100%" }} value={editableProperty.propertyType} />
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="epcRating">EPC Rating</label>
							<InputText id="epcRating" onChange={(e) => setEditableProperty({...editableProperty, epcRating: e.target.value})} required value={editableProperty.epcRating}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="serviceChargeRate">Service Charge Rate (%) <span style={{color:"red"}}>*</span></label>
							<InputNumber id="serviceChargeRate" onChange={(e) => setEditableProperty({...editableProperty, serviceChargeRate: e.value || 0})} required value={editableProperty.serviceChargeRate}/>
						</div>
					</Col>
				</Row>
				{isPropertyUpdating === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid edit-utility" draggable={false} footer={() => renderEditUtilityModalFooter()} header="Utility Details" modal onHide={() => setShowUtilityModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showUtilityModal}>
				{selectedUtility === "Electric" && 
					<div id="electricity">
						<Row>
							<Col>
								<div className="field">
									<label htmlFor="utilityProvider">Electicity Provider</label>
									<InputText id="utilityProvider" onChange={(e) => setEditableProperty({...editableProperty, electricitySupplier: e.target.value})} value={editableProperty.electricitySupplier}/>
								</div>
							</Col>
							<Col>
								<div className="field">
									<label htmlFor="utilityLocation">Meter Location</label>
									<InputText id="utilityLocation" onChange={(e) => setEditableProperty({...editableProperty, electricityMeterLocation: e.target.value})} value={editableProperty.electricityMeterLocation}/>
								</div>
							</Col>
						</Row>
					</div>
				}
				{selectedUtility === "Gas" && 
					<div id="gas">
						<Row>
							<Col>
								<div className="field">
									<label htmlFor="utilityProvider">Gas Provider</label>
									<InputText id="utilityProvider" onChange={(e) => setEditableProperty({...editableProperty, gasSupplier: e.target.value})} value={editableProperty.gasSupplier}/>
								</div>
							</Col>
							<Col>
								<div className="field">
									<label htmlFor="utilityLocation">Meter Location</label>
									<InputText id="utilityLocation" onChange={(e) => setEditableProperty({...editableProperty, gasMeterLocation: e.target.value})} value={editableProperty.gasMeterLocation}/>
								</div>
							</Col>
						</Row>
					</div>
				}
				{selectedUtility === "Water" && 
					<div id="water">
						<Row>
							<Col>
								<div className="field">
									<label htmlFor="utilityProvider">Water Provider</label>
									<InputText id="utilityProvider" onChange={(e) => setEditableProperty({...editableProperty, waterSupplier: e.target.value})} value={editableProperty.waterSupplier}/>
								</div>
							</Col>
							<Col>
								<div className="field">
									<label htmlFor="utilityLocation">Meter Location</label>
									<InputText id="utilityLocation" onChange={(e) => setEditableProperty({...editableProperty, waterMeterLocation: e.target.value})} value={editableProperty.waterMeterLocation}/>
								</div>
							</Col>
						</Row>
					</div>
				}
				{isPropertyUpdating === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid remove-document-confirmation" draggable={false} footer={() => renderRemovePropertyDocumentModalFooter()} header="Remove Document" modal onHide={() => setShowRemovePropertyDocumentModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showRemovePropertyDocumentModal}>
				<Row>
					<Col>
						<div style={{textAlign: "center"}}>
							<span style={{display: "block"}}>Are you sure you wish to remove the {deletablePropertyDocument.documentType} document?</span>
							<span style={{display: "block"}}>This action may not be undone!</span>
						</div>
					</Col>
				</Row>
				{isRemovePropertyDocumentLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid remove-property-confirmation" draggable={false} footer={() => renderRemovePropertyModalFooter()} header="Remove Property" modal onHide={() => setShowRemovePropertyModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showRemovePropertyModal}>
				<Row>
					<Col>
						<div style={{textAlign: "center"}}>
							<span style={{display: "block"}}>Are you sure you wish to remove the property?</span>
							<span style={{display: "block"}}>This action may not be undone!</span>
						</div>
					</Col>
				</Row>
				{isRemovePropertyLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid view-documents" draggable={false} header="View Documents" modal onHide={() => setShowDocumentsModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showDocumentsModal}>
				<Row>
					<Col>
						<DataTable scrollable scrollHeight="flex" value={((property.propertyDocuments || []).filter((document) => document.status !== ObjectStatus.Removed))}>
							<Column field="documentType" header="Document Type" sortable></Column>
							<Column field="validFrom" header="Valid From" sortable style={{width: 135}} body={(rowData) => FormatDate(rowData.validFrom)}></Column>
							<Column field="validTo" header="Valid To" sortable style={{width: 135}} body={(rowData) => FormatDate(rowData.validTo)}></Column>
							<Column field="files" header="Files" sortable style={{textAlign: "right", width: 75}} body={(rowData) => JSON.parse(rowData.files || "[]").length}></Column>
							<Column body={documentGridButtons} style={{width: 50}}></Column>
						</DataTable>
					</Col>
				</Row>
			</Dialog>
			<Dialog className="p-fluid view-events" draggable={false} header="View Events" modal onHide={() => setShowEventsModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEventsModal}>
				<Row>
					<Col>
						<DataTable scrollable scrollHeight="flex" value={property.propertyEvents}>
							<Column field="description" header="Description" sortable></Column>
							<Column field="eventType" header="Type" sortable style={{width: 250}} body={(rowData) => EventType[rowData.eventType].replace(/([A-Z])/g, " $1")}></Column>
							<Column body={eventGridButtons} style={{width: 50}}></Column>
						</DataTable>
					</Col>
				</Row>
			</Dialog>
			<Dialog className="p-fluid view-rent-payments" draggable={false} footer={() => renderViewRentPaymentsModalFooter()} header="View Rent Payments" modal onHide={() => setShowViewRentPaymentsModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showViewRentPaymentsModal}>
				<Row>
					<Col>
						<DataTable scrollable scrollHeight="flex" value={viewRentPayments}>
							<Column field="paymentValue" header="Payment Amount" sortable></Column>
							<Column field="paymentType" header="Payment Type" sortable body={(rowData) => RentPaymentType[rowData.paymentType]}></Column>
							<Column field="datePaid" header="Date Paid" sortable style={{width: 135}} body={(rowData) => FormatDate(rowData.datePaid)}></Column>
						</DataTable>
					</Col>
				</Row>
			</Dialog>
			<Dialog className="p-fluid view-tenancy" draggable={false} footer={() => renderViewTenancyModalFooter()} header="View Tenancy Agreement" modal onHide={() => setShowViewTenancyModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showViewTenancyModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="startDate">Start Date</label>
							<InputText disabled id="startDate" value={FormatDate(property.tenancyAgreements?.[0]?.startDate)}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="contractValidUntil">Contract Valid Until</label>
							<InputText disabled id="contractValidUntil" value={FormatDate(property.tenancyAgreements?.[0]?.contractValidUntil)}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="rentPrice">Rent Price</label>
							<InputNumber disabled id="rentPrice" value={property.tenancyAgreements?.[0]?.rentPrice || 0}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="rentSchedule">Rent Schedule</label>
							<InputText disabled id="rentSchedule" value={RentSchedule[property.tenancyAgreements?.[0]?.rentSchedule || 0]}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="generateRentChargeOn">Generate Rent Charge</label>
							<InputText disabled id="generateRentChargeOn" value={FormatDate(property.tenancyAgreements?.[0]?.generateRentChargeOn)}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="primaryContactTenant">Primary Contact Tenant</label>
							<InputText disabled id="primaryContactTenant" value={property.tenancyAgreements?.[0]?.primaryContactTenant?.name}/>
						</div>
					</Col>
				</Row>
				{isPropertyUpdating === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			{isLoading === true &&
				<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 19999}}>
					<ProgressSpinner strokeWidth="5" style={{left: "50%", position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
				</div>
			}
		</>
	);
}