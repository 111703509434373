import { faChartLineUp, faCircleCheck, faCircleExclamation, faCirclePlus, faCircleQuestion, faClose, faEnvelope, faEye, faHammerBrush, faHouseBuilding, faMoneyBill, faPencil, faPhone } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { colours } from "src/app/Styles";
import { EventType, PropertyType, RentChargeStatus } from "src/app/enums/Index";
import { FormatDate, FormatNumber, GetPropertiesFromEnum, Timeout } from "src/app/helpers/Index";
import { Landlord, LandlordPropertyPerformanceSummary, Property, PropertyEvent, RentCharge, RentPayment } from "src/app/interfaces/Index";
import { LandlordService, PropertyService, RentChargeService } from "src/app/services/Index";

export const AdminLandlordsId = ({toast}: any) => {
	const navigate = useNavigate();
	const params = useParams();
	const [editableLandlord, setEditableLandlord] = useState<Landlord>(new Landlord());
	const [isAddPropertyLoading, setIsAddPropertyLoading] = useState(false);
	const [isLandlordUpdating, setIsLandlordUpdating] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRemoveLandlordLoading, setIsRemoveLandlordLoading] = useState(false);
	const [isRemovePropertyLoading, setIsRemovePropertyLoading] = useState(false);
	const [landlord, setLandlord] = useState<Landlord>(new Landlord());
	const [modalPropertyEvents, setModalPropertyEvents] = useState<PropertyEvent[]>([]);
	const [newProperty, setNewProperty] = useState<Property>(new Property());
	const [propertyPerformance, setPropertyPerformance] = useState<LandlordPropertyPerformanceSummary>();
	const [propertyToRemove, setPropertyToRemove] = useState<Property>(new Property());
	const [rent, setRent] = useState<RentCharge[]>();
	const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);
	const [showEditLandlordModal, setShowEditLandlordModal] = useState(false);
	const [showEventsModal, setShowEventsModal] = useState(false);
	const [showRemoveLandlordModal, setShowRemoveLandlordModal] = useState(false);
	const [showRemovePropertyModal, setShowRemovePropertyModal] = useState(false);
	const propertyTypes = GetPropertiesFromEnum(PropertyType);

	useEffect(() => {
		setIsLoading(true);
		getLandlord();
		getPropertyPerformances();
	}, []);

	const createProperty = async () => {
		setIsAddPropertyLoading(true);
		const res = await PropertyService.createSingle(newProperty);
		switch(res.status) {
			case 200:
				setShowAddPropertyModal(false);
				getLandlord();
				setIsAddPropertyLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddPropertyLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddPropertyLoading(false);
				break;
		}
	}

	const filterPropertyEvents = (data: Landlord) => {
		let events: PropertyEvent[] = [];
		(data.properties || []).forEach((property) => {
			const startingDate = property.tenancyAgreements?.[0]?.startDate;
			if (startingDate && property.propertyEvents) {
				const tempEvents = property.propertyEvents.filter((events) => new Date(events.createdAt).getTime() > new Date(startingDate).getTime());
				tempEvents.forEach((event) => event.property = new Property({addressLine1: property.addressLine1}));
				if (tempEvents) events.push(...tempEvents);
			} else {
				const tempEvents = property.propertyEvents;
				if (tempEvents) {
					tempEvents.forEach((event) => event.property = new Property({addressLine1: property.addressLine1}));
					events.push(...tempEvents);
				}
			}
		});
		events.sort((a: PropertyEvent, b: PropertyEvent) => {
			const aDateDue = new Date(a.createdAt);
			const bDateDue = new Date(b.createdAt);
			if (aDateDue.getTime() > bDateDue.getTime()) return -1;
			else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
			else return 0;
		});
		setModalPropertyEvents(events);
	}

	const getLandlord = async () => {
		const {id} = params;
		const res = await LandlordService.getFullDetails(Number(id) || 0);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setLandlord(data);
				setEditableLandlord(data);
				setNewProperty(new Property({landlordId: data.id}));
				getRentFromAgreement(data?.tenancyAgreementId);
				filterPropertyEvents(data);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const getPropertyPerformances = async () => {
		const {id} = params;
		const res = await PropertyService.getPerformanceSummaryByLandlord(Number(id) || 0);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setPropertyPerformance(data);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				break;
		}
	}

	const getRentFromAgreement = async (agreementId: number) => {
		const res = await RentChargeService.getByAgreement(agreementId);
		switch(res.status) {
			case 200:
				const data = await res.json();
				data.sort((a: RentCharge, b: RentCharge) => {
					const aDateDue = new Date(a.dateDue);
					const bDateDue = new Date(b.dateDue);
					if (aDateDue.getTime() > bDateDue.getTime()) return -1;
					else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
					else return 0;
				});
				setRent(data);
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const propertyGridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions" style={{display: "flex", flexDirection: "row"}}>
					<Button onClick={() => navigate(`/admin/main/properties/${rowData.id}`)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
					</Button>
					<Button onClick={() => showRemoveProperty(rowData)} style={{backgroundColor: colours.buttons.cancel, borderColor: colours.buttons.cancel, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faClose} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const removeLandlord = async () => {
		setIsRemoveLandlordLoading(true);
		const res = await LandlordService.deleteSingleById(landlord.id);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Landlord removed successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				navigate("/admin/main/landlords");
				setIsRemoveLandlordLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsRemoveLandlordLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsRemoveLandlordLoading(false);
				break;
		}
	}

	const removeProperty = async () => {
		setIsRemovePropertyLoading(true);
		const property = propertyToRemove;
		property.landlordId = null!;
		const res = await PropertyService.updateSingleById(property.id, property);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Property removed successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				await Timeout(100);
				getLandlord();
				getPropertyPerformances();
				setShowRemovePropertyModal(false);
				setIsRemovePropertyLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsRemovePropertyLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsRemovePropertyLoading(false);
				break;
		}
	}

	const renderAddPropertyModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowAddPropertyModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Property" onClick={() => createProperty()}></Button>
			</>
		);
	}

	const renderEditLandlordModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowEditLandlordModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => saveLandlord()}></Button>
			</>
		);
	}

	const renderRemoveLandlordModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowRemoveLandlordModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Confirm" onClick={() => removeLandlord()}></Button>
			</>
		);
	}

	const renderRemovePropertyModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowRemovePropertyModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Confirm" onClick={() => removeProperty()}></Button>
			</>
		);
	}

	const saveLandlord = async () => {
		setIsLandlordUpdating(true);
		const res = await LandlordService.updateSingleById(editableLandlord.id, editableLandlord);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				getLandlord();
				setIsLandlordUpdating(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLandlordUpdating(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLandlordUpdating(false);
				break;
		}
	}

	const showRemoveProperty = (rowData: any) => {
		setPropertyToRemove(rowData);
		setShowRemovePropertyModal(true);
	}

	return (
		<>
			<Row>
				<Col style={{marginBottom: 10}}>
					{(landlord.properties === null || landlord.properties === undefined || landlord.properties.length === 0) &&
						<Button onClick={() => setShowRemoveLandlordModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faClose} style={{fontSize: 15, marginRight: 10, width: 15}}/>Remove Landlord</Button>
					}
					<Button onClick={() => setShowEditLandlordModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faPencil} style={{fontSize: 15, marginRight: 10, width: 15}}/>Edit Landlord</Button>
					<Button onClick={() => setShowAddPropertyModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Add Property</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="card" style={{display: "flex", flexDirection: "row", marginBottom: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
							<span style={{fontSize: 25, fontWeight: "bold"}}>{`${landlord.name}`}</span>
							<span>{`${landlord.properties?.length} Propert${landlord.properties?.length === 1 ? "y" : "ies"}`}</span>
						</div>
						<div style={{alignItems: "center", display: "flex", flexDirection: "row"}}>
							<a href={`tel:${landlord.phoneNumber}`} style={{color: colours.black, cursor: "pointer", marginRight: 20}}>
								<FontAwesomeIcon icon={faPhone} style={{fontSize: 35}}/>
							</a>
							<a href={`mailto:${landlord.emailAddress}`} style={{color: colours.black, cursor: "pointer"}}>
								<FontAwesomeIcon icon={faEnvelope} style={{fontSize: 35}}/>
							</a>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={5}>
					<div className="card" style={{paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faChartLineUp} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Performance</span>
						</div>
						<div style={{display: "flex"}}>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.totalRent : 0, true)}</span>
								<span>Total Rent</span>
							</div>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.monthlyRent : 0, true)}</span>
								<span>Monthly Rent</span>
							</div>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.serviceCharges : 0, true)}</span>
								<span>Service Charges</span>
							</div>
							<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
								<span style={{fontSize: 20, fontWeight: "bold"}}>{FormatNumber(propertyPerformance ? propertyPerformance.maintenance : 0, true)}</span>
								<span>Maintenance</span>
							</div>
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHammerBrush} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Events</span>
						</div>
						<span>Total Events: {modalPropertyEvents.length}</span>
						{modalPropertyEvents.length > 0 &&
							<div style={{marginTop: 10}}>
								<span className="span-link" onClick={() => setShowEventsModal(true)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faEye} style={{fontSize: 20, marginRight: 5, width: 20}}/> View Events</span>
							</div>
						}
					</div>
				</Col>
				<Col xs={7}>
					<div className="card" style={{paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHouseBuilding} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Portfolio Summary</span>
							<DataTable scrollable scrollHeight="flex" value={landlord.properties}>
								<Column body={propertyGridButtons} style={{width: 50}}></Column>
								<Column field="addressLine1" header="Address" sortable></Column>
								<Column field="postcode" header="Postcode" sortable style={{width: 125}}></Column>
								<Column header="Rent Amount" sortable style={{textAlign: "right", width: 160}} body={(rowData) => rowData.tenancyAgreements[0] ? FormatNumber(rowData.tenancyAgreements[0].rentPrice, true) : FormatNumber(0, true)}></Column>
								<Column header="Tenants" sortable style={{textAlign: "right", width: 110}} body={(rowData) => rowData.tenancyAgreements[0] ? rowData.tenancyAgreements[0].tenants.length : 0}></Column>
							</DataTable>
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faMoneyBill} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Portfolio Rent Book</span>
							<DataTable scrollable scrollHeight="flex" value={rent}>
								<Column body={(rowData) => FormatDate((rowData.dateDue))} field="dateDue" header="Due Date" sortable style={{width: 125}}></Column>
								<Column field="rentAmount" header="Rent Amount" sortable style={{width: 155}}></Column>
								<Column field="rentPayments[0].paymentValue" header="Rent Paid" sortable style={{width: 125}} body={(rowData) => {
									const totalPaid = rowData.rentPayments.length > 0 ? rowData.rentPayments.map((payment: RentPayment) => payment.paymentValue).reduce((a: number, b: number) => a + b) : 0;
									return FormatNumber(totalPaid);
								}}></Column>
								<Column body={(rowData) => FormatDate((rowData.datePaid))} field="datePaid" header="Paid On" sortable style={{width: 125}}></Column>
								<Column field="tenant?.tenancyAgreement?.primaryContactTenant" header="Tenant" sortable></Column>
								<Column field="chargeStatus" header="Status" sortable style={{width: 120}} body={(rowData) => {
									switch (rowData.chargeStatus as RentChargeStatus) {
										case RentChargeStatus.Paid:
											return (<span style={{color: "#7FD764"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Paid</span>);
										case RentChargeStatus.Due:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Due</span>);
										case RentChargeStatus.Late:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Late</span>);
										case RentChargeStatus.OverDue:
											return (<span style={{color: "#F0552A"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Overdue</span>);
										case RentChargeStatus.NotDue:
											return (<span><FontAwesomeIcon icon={faCircleQuestion} style={{fontSize: 15, width: 15}}/> Not Due</span>);
									}
								}}></Column>
							</DataTable>
						</div>
					</div>
				</Col>
			</Row>
			<Dialog className="p-fluid add-property" draggable={false} footer={() => renderAddPropertyModalFooter()} header="Add Property" modal onHide={() => setShowAddPropertyModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddPropertyModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine1">Address Line 1</label>
							<InputText id="addressLine1" onChange={(e) => setNewProperty({...newProperty, addressLine1: e.target.value})} value={newProperty.addressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="addressLine2">Address Line 2</label>
							<InputText id="addressLine2" onChange={(e) => setNewProperty({...newProperty, addressLine2: e.target.value})} value={newProperty.addressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine3">Address Line 3</label>
							<InputText id="addressLine3" onChange={(e) => setNewProperty({...newProperty, addressLine3: e.target.value})} value={newProperty.addressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="town">Town</label>
							<InputText id="town" onChange={(e) => setNewProperty({...newProperty, town: e.target.value})} value={newProperty.town}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="postcode">Postcode</label>
							<InputText id="postcode" onChange={(e) => setNewProperty({...newProperty, postcode: e.target.value})} value={newProperty.postcode}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="propertyType">Property Type <span style={{color:"red"}}>*</span></label>
							<Dropdown id="propertyType" onChange={(e) => setNewProperty({...newProperty, propertyType: e.value})} options={propertyTypes} optionLabel="name" optionValue="id" required style={{ width: "100%" }} value={newProperty.propertyType}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="epcRating">EPC Rating</label>
							<Dropdown id="epcRating" onChange={(e) => setNewProperty({...newProperty, epcRating: e.value})} options={["A", "B", "C", "D", "E", "F", "G"]} showClear style={{ width: "100%" }} value={newProperty.epcRating}/>
						</div>
					</Col>
					<Col></Col>
				</Row>
				{isAddPropertyLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid edit-landlord" draggable={false} footer={() => renderEditLandlordModalFooter()} header="Edit Landlord" modal onHide={() => setShowEditLandlordModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEditLandlordModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="name">Name</label>
							<InputText id="name" onChange={(e) => setEditableLandlord({...editableLandlord, name: e.target.value})} value={editableLandlord.name}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="emailAddress">Email Address</label>
							<InputText id="emailAddress" onChange={(e) => setEditableLandlord({...editableLandlord, emailAddress: e.target.value})} value={editableLandlord.emailAddress}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine1">Address Line 1</label>
							<InputText id="addressLine1" onChange={(e) => setEditableLandlord({...editableLandlord, addressLine1: e.target.value})} value={editableLandlord.addressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="addressLine2">Address Line 2</label>
							<InputText id="addressLine2" onChange={(e) => setEditableLandlord({...editableLandlord, addressLine2: e.target.value})} value={editableLandlord.addressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine3">Address Line 3</label>
							<InputText id="addressLine3" onChange={(e) => setEditableLandlord({...editableLandlord, addressLine3: e.target.value})} value={editableLandlord.addressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="town">Town</label>
							<InputText id="town" onChange={(e) => setEditableLandlord({...editableLandlord, town: e.target.value})} value={editableLandlord.town}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="postcode">Postcode</label>
							<InputText id="postcode" onChange={(e) => setEditableLandlord({...editableLandlord, postcode: e.target.value})} value={editableLandlord.postcode}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="phoneNumber">Phone Number</label>
							<InputText id="phoneNumber" onChange={(e) => setEditableLandlord({...editableLandlord, phoneNumber: e.target.value})} value={editableLandlord.phoneNumber}/>
						</div>
					</Col>
				</Row>
				{isLandlordUpdating === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid remove-landlord-confirmation" draggable={false} footer={() => renderRemoveLandlordModalFooter()} header="Remove Landlord" modal onHide={() => setShowRemoveLandlordModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showRemoveLandlordModal}>
				<Row>
					<Col>
						<div style={{textAlign: "center"}}>
							<span style={{display: "block"}}>Are you sure you wish to remove the landlord?</span>
							<span style={{display: "block"}}>This action may not be undone!</span>
						</div>
					</Col>
				</Row>
				{isRemoveLandlordLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid remove-property-confirmation" draggable={false} footer={() => renderRemovePropertyModalFooter()} header="Remove Property" modal onHide={() => setShowRemovePropertyModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showRemovePropertyModal}>
				<Row>
					<Col>
						<div style={{textAlign: "center"}}>
							<span style={{display: "block"}}>Are you sure you wish to remove the property at {propertyToRemove.addressLine1} from the landlord?</span>
							<span style={{display: "block"}}>This action may not be undone!</span>
						</div>
					</Col>
				</Row>
				{isRemovePropertyLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid view-events" draggable={false} header="View Events" modal onHide={() => setShowEventsModal(false)} style={{minWidth: 750, position: "relative", width: "40%"}} visible={showEventsModal}>
				<Row>
					<Col>
						<DataTable scrollable scrollHeight="flex" value={modalPropertyEvents}>
							<Column field="description" header="Description" sortable></Column>
							<Column field="eventType" header="Type" sortable style={{width: 250}} body={(rowData) => EventType[rowData.eventType].replace(/([A-Z])/g, " $1")}></Column>
							<Column field="property.addressLine1" header="Property" sortable style={{width: 150}}></Column>
						</DataTable>
					</Col>
				</Row>
			</Dialog>
			{isLoading === true &&
				<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 19999}}>
					<ProgressSpinner strokeWidth="5" style={{left: "50%", position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
				</div>
			}
		</>
	);
}