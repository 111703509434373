import { Guid } from "guid-typescript";
import { PropertyEvent } from "src/app/interfaces/Index";
import { Service } from "./Service";

const endpoint = "PropertyEvent";
export const PropertyEventService = {
	endpoint,
	createSingle: async (data: PropertyEvent) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	updateSingleById: async (id: number, data: PropertyEvent) => await Service.updateSingleById(endpoint, id, data)
}