import { faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { colours } from "src/app/Styles";
import { Landlord } from "src/app/interfaces/Index";
import { LandlordService } from "src/app/services/Index";

export const AdminLandlords = ({toast}: any) => {
	const navigate = useNavigate();
	const dataTable = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isAddLandlordLoading, setIsAddLandlordLoading] = useState(false);
	const [newLandlord, setNewLandlord] = useState(new Landlord());
	const [landlords, setLandlords] = useState([] as Landlord[]);
	const [showAddLandlordModal, setShowAddLandlordModal] = useState(false);

	useEffect(() => {
		getLandlords();
	}, []);

	const createLandlord = async () => {
		setIsAddLandlordLoading(true);
		const res = await LandlordService.createSingle(newLandlord);
		switch(res.status) {
			case 200:
				getLandlords();
				setIsAddLandlordLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddLandlordLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddLandlordLoading(false);
				break;
		}
	}

	const getLandlords = async () => {
		setIsLoading(true);
		const res = await LandlordService.getAll();
		switch(res.status) {
			case 200:
				const data = await res.json();
				setLandlords(data);
				if (newLandlord.emailAddress) {
					const fullNewLandlord = data.find((landlord: Landlord) => landlord.emailAddress === newLandlord.emailAddress);
					navigate(`${fullNewLandlord.id}`);
				}
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const gridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions">
					<Button onClick={() => navigate(`${rowData.id}`)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const renderLeftToolbarButtons = () => {
		return (
			<Button icon="pi pi-plus" label="Add New Landlord" style={{backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginLeft: 10}} onClick={() => setShowAddLandlordModal(true)}></Button>
		);
	}

	const renderRightToolbarButtons = () => {
		return (
			<Button icon="pi pi-download" label="Export" style={{backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10}} onClick={() => dataTable.current ? (dataTable.current as any).exportCSV() : null}></Button>
		);
	}

	const renderNewLandlordModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowAddLandlordModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-plus" label="Create New Landlord" onClick={() => createLandlord()}></Button>
			</>
		);
	}

	return (
		<>
			<Row>
				<Col>
					<div className="card">
						<h5>Landlords</h5>
						<Toolbar end={() => renderRightToolbarButtons()} start={() => renderLeftToolbarButtons()}></Toolbar>
						<DataTable loading={isLoading} ref={dataTable} scrollable scrollHeight="flex" style={{height: "calc(100vh - 17.5rem)"}} filterDisplay="row" value={landlords}>
							<Column body={gridButtons} style={{width: 50}}></Column>
							<Column field="name" header="Name" sortable filter filterMatchMode="contains"></Column>
						</DataTable>
					</div>
				</Col>
			</Row>
			<Dialog className="p-fluid add-landlord" draggable={false} footer={() => renderNewLandlordModalFooter()} header="Add Property" modal onHide={() => setShowAddLandlordModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddLandlordModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="name">Name</label>
							<InputText id="name" onChange={(e) => setNewLandlord({...newLandlord, name: e.target.value})} value={newLandlord.name}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="emailAddress">Email Address</label>
							<InputText id="emailAddress" onChange={(e) => setNewLandlord({...newLandlord, emailAddress: e.target.value})} value={newLandlord.emailAddress}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine1">Address Line 1</label>
							<InputText id="addressLine1" onChange={(e) => setNewLandlord({...newLandlord, addressLine1: e.target.value})} value={newLandlord.addressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="addressLine2">Address Line 2</label>
							<InputText id="addressLine2" onChange={(e) => setNewLandlord({...newLandlord, addressLine2: e.target.value})} value={newLandlord.addressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="addressLine3">Address Line 3</label>
							<InputText id="addressLine3" onChange={(e) => setNewLandlord({...newLandlord, addressLine3: e.target.value})} value={newLandlord.addressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="town">Town</label>
							<InputText id="town" onChange={(e) => setNewLandlord({...newLandlord, town: e.target.value})} value={newLandlord.town}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="postcode">Postcode</label>
							<InputText id="postcode" onChange={(e) => setNewLandlord({...newLandlord, postcode: e.target.value})} value={newLandlord.postcode}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="phoneNumber">Phone Number</label>
							<InputText id="phoneNumber" onChange={(e) => setNewLandlord({...newLandlord, phoneNumber: e.target.value})} value={newLandlord.phoneNumber}/>
						</div>
					</Col>
				</Row>
				{isAddLandlordLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
		</>
	);
}