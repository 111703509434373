export interface RequestPasswordResetToken {
	emailAddress?: string;
}

export class RequestPasswordResetToken implements RequestPasswordResetToken {
	constructor(data?: Partial<RequestPasswordResetToken>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}