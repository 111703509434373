import { faBan, faCircleCheck, faCircleExclamation, faCirclePlus, faCircleQuestion, faClose, faEye, faHammerBrush, faHandshake, faMoneyBill, faPeople } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { colours } from "src/app/Styles";
import { EventType, RentChargeStatus } from "src/app/enums/Index";
import { FormatDate, FormatNumber, Timeout } from "src/app/helpers/Index";
import { PropertyEvent, RentCharge, RentPayment, TenancyAgreement, Tenant } from "src/app/interfaces/Index";
import { FileService, RentChargeService, TenancyAgreementService, TenantService } from "src/app/services/Index";

export const AdminTenancyAgreementId = ({toast}: any) => {
	const params = useParams();
	const navigate = useNavigate();
	const contractFileRef = useRef(null);
	const [contractModalFromTenant, setContractModalFromTenant] = useState(false);
	const [editableTenancyAgreement, setEditableTenancyAgreement] = useState<TenancyAgreement>(new TenancyAgreement());
	const [isAddTenantLoading, setIsAddTenantLoading] = useState(false);
	const [isAddExistingTenantLoading, setIsAddExistingTenantLoading] = useState(false);
	const [isAddTenantContractLoading, setIsAddTenantContractLoading] = useState(false);
	const [isCancelTenancyAgreementLoading, setIsCancelTenancyAgreementLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRemoveTenantLoading, setIsRemoveTenantLoading] = useState(false);
	const [modalPropertyEvents, setModalPropertyEvents] = useState<PropertyEvent[]>([]);
	const [newTenant, setNewTenant] = useState<Tenant>(new Tenant());
	const [rent, setRent] = useState<RentCharge[]>();
	const [selectedTenantId, setSelectedTenantId] = useState<number>(-1);
	const [showAddTenantModal, setShowAddTenantModal] = useState(false);
	const [showAddExistingTenantModal, setShowAddExistingTenantModal] = useState(false);
	const [showAddTenantContractModal, setShowAddTenantContractModal] = useState(false);
	const [showCancelTenancyAgreementModal, setShowCancelTenancyAgreementModal] = useState(false);
	const [showEventsModal, setShowEventsModal] = useState(false);
	const [showRemoveTenantModal, setShowRemoveTenantModal] = useState(false);
	const [tenancyAgreement, setTenancyAgreement] = useState<TenancyAgreement>(new TenancyAgreement());
	const [tenants, setTenants] = useState<Tenant[]>([]);
	const [tenantToRemove, setTenantToRemove] = useState<Tenant>(new Tenant());
	const [uploadedFileName, setUploadedFileName] = useState("");

	useEffect(() => {
		setIsLoading(true);
		getTenancyAgreement();
		getTenants();
	}, []);

	const cancelTenancyAgreement = async () => {
		setIsCancelTenancyAgreementLoading(true);
		const res = await TenancyAgreementService.updateSingleById(editableTenancyAgreement.id, editableTenancyAgreement);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				getTenancyAgreement();
				getTenants();
				setShowCancelTenancyAgreementModal(false);
				setIsCancelTenancyAgreementLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsCancelTenancyAgreementLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsCancelTenancyAgreementLoading(false);
				break;
		}
	}

	const closeAddExistingTenantModal = () => {
		setShowAddExistingTenantModal(false);
		setSelectedTenantId(-1);
	}

	const closeAddTenantModal = () => {
		setShowAddTenantModal(false);
		setNewTenant(new Tenant());
		setSelectedTenantId(-1);
	}

	const closeContractModal = () => {
		setShowAddTenantContractModal(false);
		setUploadedFileName("");
		setSelectedTenantId(-1);
		setContractModalFromTenant(false);
	}

	const createTenant = async () => {
		setIsAddTenantLoading(true);
		const res = await TenantService.createSingle(newTenant);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Tenant Successfully Added", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				getTenancyAgreement();
				getTenants();
				setNewTenant(new Tenant({tenancyAgreementId: tenancyAgreement.id}));
				setShowAddTenantModal(false);
				setShowAddTenantContractModal(true);
				setContractModalFromTenant(true);
				setIsAddTenantLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddTenantLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddTenantLoading(false);
				break;
		}
	}

	const filterPropertyEvents = (data: TenancyAgreement) => {
		const startingDate = data.startDate;
		let events = [];
		if (startingDate && data.property?.propertyEvents) events = data.property?.propertyEvents?.filter((events) => new Date(events.createdAt).getTime() > new Date(startingDate).getTime());
		else events = data.property?.propertyEvents || [];
		events.sort((a: PropertyEvent, b: PropertyEvent) => {
			const aDateDue = new Date(a.createdAt);
			const bDateDue = new Date(b.createdAt);
			if (aDateDue.getTime() > bDateDue.getTime()) return -1;
			else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
			else return 0;
		});
		setModalPropertyEvents(events);
	}

	const getCalendarDate = (e: any, canBeNull?: boolean) => {
		if (canBeNull && e.value === null) return;
		else if (e.value === null) return new Date();
		else return new Date(e.value);
	}

	const getTenancyAgreement = async () => {
		const {id} = params;
		const res = await TenancyAgreementService.getFullDetails(Number(id) || 0);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setTenancyAgreement(data);
				setEditableTenancyAgreement(data);
				setNewTenant(new Tenant({tenancyAgreementId: data.id}));
				getRentFromAgreement(data?.id);
				filterPropertyEvents(data);
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const getRentFromAgreement = async (agreementId: number) => {
		const res = await RentChargeService.getByAgreement(agreementId);
		switch(res.status) {
			case 200:
				const data = await res.json();
				data.sort((a: RentCharge, b: RentCharge) => {
					const aDateDue = new Date(a.dateDue);
					const bDateDue = new Date(b.dateDue);
					if (aDateDue.getTime() > bDateDue.getTime()) return -1;
					else if (aDateDue.getTime() < bDateDue.getTime()) return 1;
					else return 0;
				});
				setRent(data);
				setIsLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsLoading(false);
				break;
		}
	}

	const getTenants = async () => {
		const res = await TenantService.getAll();
		switch(res.status) {
			case 200:
				const data = await res.json();
				const unassignedTenants = data.filter((data: Tenant) => data.tenancyAgreementId === null || data.tenancyAgreementId === undefined);
				setTenants(unassignedTenants);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				break;
		}
	}

	const linkContract = async () => {
		setIsAddTenantContractLoading(true);
		const res = await TenancyAgreementService.updateSingleById(tenancyAgreement.id, tenancyAgreement);
		switch(res.status) {
			case 200:
				getTenancyAgreement();
				closeContractModal();
				setIsAddTenantContractLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddTenantContractLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddTenantContractLoading(false);
				break;
		}
	}

	const removeTenant = async () => {
		setIsRemoveTenantLoading(true);
		const tenant = tenantToRemove;
		tenant.tenancyAgreementId = null!;
		const res = await TenantService.updateSingleById(tenant.id, tenant);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Tenant removed successfully", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				await Timeout(100);
				getTenancyAgreement();
				getTenants();
				setShowRemoveTenantModal(false);
				setIsRemoveTenantLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsRemoveTenantLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsRemoveTenantLoading(false);
				break;
		}
	}

	const renderAddExistingTenantModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => closeAddExistingTenantModal()}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Tenant" onClick={() => saveTenantToAgreement()}></Button>
			</>
		);
	}

	const renderAddTenantModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => closeAddTenantModal()}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Tenant" onClick={() => createTenant()}></Button>
			</>
		);
	}

	const renderAddTenantContractModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => closeContractModal()}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Add Contract" onClick={() => linkContract()}></Button>
			</>
		);
	}

	const renderCancelTenancyAgreementModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowCancelTenancyAgreementModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Confirm" onClick={() => cancelTenancyAgreement()}></Button>
			</>
		);
	}

	const renderRemoveTenantModalFooter = () => {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => setShowRemoveTenantModal(false)}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Confirm" onClick={() => removeTenant()}></Button>
			</>
		);
	}

	const saveTenantToAgreement = async () => {
		if (selectedTenantId === -1) return;
		setIsAddExistingTenantLoading(true);
		const tenant = tenants.find((tenant) => tenant.id = selectedTenantId)!;
		tenant.tenancyAgreementId = tenancyAgreement.id;
		const res = await TenantService.updateSingleById(tenant.id, tenant);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Tenant Successfully Added", life: 5000, severity: "success", summary: "Success"});
				setIsLoading(true);
				getTenancyAgreement();
				getTenants();
				setShowAddExistingTenantModal(false);
				setShowAddTenantContractModal(true);
				setContractModalFromTenant(true);
				setIsAddExistingTenantLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddExistingTenantLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddExistingTenantLoading(false);
				break;
		}
	}

	const showRemoveTenant = (rowData: any) => {
		setTenantToRemove(rowData);
		setShowRemoveTenantModal(true);
	}

	const tenantGridButtons = (rowData: any) => {
		return (
			<>
				<div className="actions" style={{display: "flex", flexDirection: "row"}}>
					<Button onClick={() => navigate(`/admin/main/tenants/${rowData.id}`)} style={{backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faEye} style={{color: colours.white}}/>
					</Button>
					<Button onClick={() => showRemoveTenant(rowData)} style={{backgroundColor: colours.buttons.cancel, borderColor: colours.buttons.cancel, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
						<FontAwesomeIcon icon={faClose} style={{color: colours.white}}/>
					</Button>
				</div>
			</>
		);
	}

	const uploadContract = async (data: any) => {
		setIsAddTenantContractLoading(true);
		const uploadData = new FormData();
		uploadData.append("containerName", "uploads");
		uploadData.append("files", data.files[0]);
		const res = await FileService.upload(uploadData);
		switch(res.status) {
			case 200:
				const data = await res.json();
				setUploadedFileName(data[0].name);
				const tenant = tenancyAgreement.tenants!.find((tenant) => tenant.id === selectedTenantId)!;
				const contract = {fileName: data[0].name, lookupId: data[0].lookupId, tenantName: tenant.name};
				if (tenancyAgreement.contracts === null || tenancyAgreement.contracts === undefined || tenancyAgreement.contracts === "" || tenancyAgreement.contracts === "[]") setTenancyAgreement({...tenancyAgreement, contracts: JSON.stringify([contract])});
				else {
					const contractString = JSON.parse(tenancyAgreement.contracts!);
					contractString.push(contract);
					setTenancyAgreement({...tenancyAgreement, contracts: JSON.stringify(contractString)});
				}
				if (contractFileRef.current) (contractFileRef.current as any).clear();
				setIsAddTenantContractLoading(false);
				break;
			case 401:
				navigate("/admin");
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "error", summary: "Session Expired!"});
				setIsAddTenantContractLoading(false);
				break;
			default:
				if (toast.current) (toast.current as any).show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsAddTenantContractLoading(false);
				break;
		}
	}

	const uploadError = () => {
		if (toast.current) (toast.current as any).show({closable: false, detail: "Please ensure you are uploading an image file no larger than 1MB.", life: 5000, severity: "error", summary: "Upload Failed"});
	}

	return (
		<>
			{(tenancyAgreement.endDate === null || tenancyAgreement.endDate === undefined) &&
				<Row>
					<Col style={{marginBottom: 10}}>
						<Button onClick={() => setShowCancelTenancyAgreementModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faBan} style={{fontSize: 15, marginRight: 10, width: 15}}/>Cancel Tenancy Agreement</Button>
						<Button onClick={() => setShowAddExistingTenantModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Add Existing Tenant</Button>
						<Button onClick={() => setShowAddTenantModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black, marginRight: 10}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Add New Tenant</Button>
						<Button onClick={() => setShowAddTenantContractModal(true)} style={{backgroundColor: colours.white, border: "1px solid rgba(0, 0, 0, 0.125)", color: colours.black}}><FontAwesomeIcon icon={faCirclePlus} style={{fontSize: 15, marginRight: 10, width: 15}}/>Add Contract</Button>
					</Col>
				</Row>
			}
			<Row>
				<Col>
					<div className="card" style={{marginBottom: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<span style={{fontSize: 25, fontWeight: "bold"}}>TenancyAgreement #{`${tenancyAgreement?.id}`}</span>
						<span>{`${tenancyAgreement?.property?.addressLine1}, ${tenancyAgreement?.property?.town}, ${tenancyAgreement?.property?.postcode}`}</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={5}>
					<div className="card" style={{paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHandshake} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Tenancy Details</span>
						</div>
						<span>Agreement Number: {tenancyAgreement?.id}</span>
						<span>Primary Contact: {tenancyAgreement?.primaryContactTenant?.name}</span>
						<span>Contract Start: {FormatDate(tenancyAgreement?.startDate)}</span>
						<span>Valid Until: {FormatDate(tenancyAgreement?.contractValidUntil)}</span>
						<span>Next Charge: {FormatDate(tenancyAgreement?.generateRentChargeOn)}</span>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faPeople} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Tenants</span>
							<DataTable scrollable scrollHeight="flex" value={tenancyAgreement?.tenants}>
								<Column field="name" header="Name" sortable></Column>
								<Column body={tenantGridButtons} style={{width: 50}}></Column>
							</DataTable>
						</div>
					</div>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faHammerBrush} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Events</span>
						</div>
						<span>Total Events: {modalPropertyEvents.length}</span>
						{modalPropertyEvents.length > 0 &&
							<div style={{marginTop: 10}}>
								<span className="span-link" onClick={() => setShowEventsModal(true)} style={{cursor: "pointer", alignItems: "center"}}><FontAwesomeIcon icon={faEye} style={{fontSize: 20, marginRight: 5, width: 20}}/> View Events</span>
							</div>
						}
					</div>
				</Col>
				<Col xs={7}>
					<div className="card" style={{marginTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
						<div style={{marginBottom: 5}}>
							<FontAwesomeIcon icon={faMoneyBill} style={{fontSize: 30, width: 40}}/>
							<span style={{fontSize: 25, fontWeight: "bold", marginLeft: 15}}>Rent Book</span>
							<DataTable scrollable scrollHeight="flex" value={rent}>
								<Column body={(rowData) => FormatDate((rowData.dateDue))} field="dateDue" header="Due Date" sortable style={{width: 125}}></Column>
								<Column field="rentAmount" header="Rent Amount" sortable style={{width: 155}}></Column>
								<Column field="rentPayments[0].paymentValue" header="Rent Paid" sortable style={{width: 125}} body={(rowData) => {
									const totalPaid = rowData.rentPayments.length > 0 ? rowData.rentPayments.map((payment: RentPayment) => payment.paymentValue).reduce((a: number, b: number) => a + b) : 0;
									return FormatNumber(totalPaid);
								}}></Column>
								<Column body={(rowData) => FormatDate((rowData.datePaid))} field="datePaid" header="Paid On" sortable style={{width: 125}}></Column>
								<Column field="tenancyAgreement.primaryContactTenant" header="Tenant" sortable></Column>
								<Column field="chargeStatus" header="Status" sortable style={{width: 120}} body={(rowData) => {
									switch (rowData.chargeStatus as RentChargeStatus) {
										case RentChargeStatus.Paid:
											return (<span style={{color: "#7FD764"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Paid</span>);
										case RentChargeStatus.Due:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Due</span>);
										case RentChargeStatus.Late:
											return (<span style={{color: "#E2CA00"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 15, width: 15}}/> Late</span>);
										case RentChargeStatus.OverDue:
											return (<span style={{color: "#F0552A"}}><FontAwesomeIcon icon={faCircleExclamation} style={{fontSize: 15, width: 15}}/> Overdue</span>);
										case RentChargeStatus.NotDue:
											return (<span><FontAwesomeIcon icon={faCircleQuestion} style={{fontSize: 15, width: 15}}/> Not Due</span>);
									}
								}}></Column>
							</DataTable>
						</div>
					</div>
				</Col>
			</Row>
			<Dialog className="p-fluid add-tenant" draggable={false} footer={() => renderAddTenantModalFooter()} header="Add Tenant" modal onHide={() => setShowAddTenantModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddTenantModal}>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="name">Name</label>
							<InputText id="name" onChange={(e) => setNewTenant({...newTenant, name: e.target.value})} value={newTenant.name}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="emailAddress">Email Address</label>
							<InputText id="emailAddress" onChange={(e) => setNewTenant({...newTenant, emailAddress: e.target.value})} value={newTenant.emailAddress}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine1">Forwarding Address Line 1</label>
							<InputText id="forwardingAddressLine1" onChange={(e) => setNewTenant({...newTenant, forwardingAddressLine1: e.target.value})} value={newTenant.forwardingAddressLine1}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine2">Forwarding Address Line 2</label>
							<InputText id="forwardingAddressLine2" onChange={(e) => setNewTenant({...newTenant, forwardingAddressLine2: e.target.value})} value={newTenant.forwardingAddressLine2}/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="field">
							<label htmlFor="forwardingAddressLine3">Forwarding Address Line 3</label>
							<InputText id="forwardingAddressLine3" onChange={(e) => setNewTenant({...newTenant, forwardingAddressLine3: e.target.value})} value={newTenant.forwardingAddressLine3}/>
						</div>
					</Col>
					<Col>
						<div className="field">
							<label htmlFor="phoneNumber">Phone Number</label>
							<InputText id="phoneNumber" onChange={(e) => setNewTenant({...newTenant, phoneNumber: e.target.value})} value={newTenant.phoneNumber}/>
						</div>
					</Col>
				</Row>
				{isAddTenantLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid add-existing-tenant" draggable={false} footer={() => renderAddExistingTenantModalFooter()} header="Add Existing Tenant" modal onHide={() => setShowAddExistingTenantModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddExistingTenantModal}>
				<Row>
					<Col>
						<Dropdown onChange={(e) => setSelectedTenantId(e.value)} options={tenants} optionLabel="name" optionValue="id" style={{width: "100%"}} value={selectedTenantId}/>
					</Col>
				</Row>
				{isAddExistingTenantLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid add-tenant-contract" draggable={false} footer={() => renderAddTenantContractModalFooter()} header="Add Tenant Contract" modal onHide={() => setShowAddTenantContractModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showAddTenantContractModal}>
				<Row>
					<Col>
						<Dropdown disabled={contractModalFromTenant} onChange={(e) => setSelectedTenantId(e.value)} options={tenancyAgreement.tenants} optionLabel="name" optionValue="id" style={{width: "100%"}} value={selectedTenantId}/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FileUpload accept="application/pdf" auto customUpload disabled={selectedTenantId === -1} maxFileSize={1000000} mode="basic" name="contract" onError={() => uploadError()} onValidationFail={() => uploadError()} ref={contractFileRef} style={{marginTop: 10}} uploadHandler={(data) => uploadContract(data)}></FileUpload>
					</Col>
					<Col>
						<div style={{alignItems: "end", display: "flex", flexDirection: "column", justifyContent: "center", height: 42, marginTop: 10}}>
							<span>{uploadedFileName}</span>
						</div>
					</Col>
				</Row>
				{isAddTenantContractLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid cancel-tenancy-agreement" draggable={false} footer={() => renderCancelTenancyAgreementModalFooter()} header="Cancel Tenancy Agreement" modal onHide={() => setShowCancelTenancyAgreementModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showCancelTenancyAgreementModal}>
				<Row>
					<Col>
						<div style={{textAlign: "center"}}>
							<span style={{display: "block"}}>Are you sure you wish to cancel the tenancy agreement?</span>
							<span style={{display: "block"}}>Please enter the cancellation date below.</span>
							<span style={{display: "block"}}>This action may not be undone!</span>
							<Calendar dateFormat="dd/mm/yy" id="dateFixed" minDate={new Date()} onChange={(e) => setEditableTenancyAgreement({...editableTenancyAgreement, endDate: getCalendarDate(e, true)})} showIcon style={{display: "flex", margin: "auto", marginTop: 20, width: 350}} value={editableTenancyAgreement.endDate}/>
						</div>
					</Col>
				</Row>
				{isCancelTenancyAgreementLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid remove-tenant-confirmation" draggable={false} footer={() => renderRemoveTenantModalFooter()} header="Remove Tenant" modal onHide={() => setShowRemoveTenantModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showRemoveTenantModal}>
				<Row>
					<Col>
						<div style={{textAlign: "center"}}>
							<span style={{display: "block"}}>Are you sure you wish to remove {tenantToRemove.name} from the tenancy agreement?</span>
							<span style={{display: "block"}}>This action may not be undone!</span>
						</div>
					</Col>
				</Row>
				{isRemoveTenantLoading === true &&
					<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 9999}}>
						<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
					</div>
				}
			</Dialog>
			<Dialog className="p-fluid view-events" draggable={false} header="View Events" modal onHide={() => setShowEventsModal(false)} style={{minWidth: 650, position: "relative", width: "40%"}} visible={showEventsModal}>
				<Row>
					<Col>
						<DataTable scrollable scrollHeight="flex" value={modalPropertyEvents}>
							<Column field="description" header="Description" sortable></Column>
							<Column field="eventType" header="Type" sortable style={{width: 250}} body={(rowData) => EventType[rowData.eventType].replace(/([A-Z])/g, " $1")}></Column>
						</DataTable>
					</Col>
				</Row>
			</Dialog>
			{isLoading === true &&
				<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 19999}}>
					<ProgressSpinner strokeWidth="5" style={{left: "50%", position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
				</div>
			}
		</>
	);
}