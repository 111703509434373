import { RentSchedule } from "src/app/enums/Index";
import { Model } from "./Model";
import { Property } from "./Property";
import { RentCharge } from "./RentCharge";
import { Tenant } from "./Tenant";

export interface TenancyAgreement extends Model {
	contractValidUntil: Date;
	contracts?: string;
	endDate?: Date;
	generateRentChargeOn?: Date;
	primaryContactTenant?: Tenant;
	primaryContactTenantId?: number;
	property?: Property;
	propertyId: number;
	rentCharges?: RentCharge[];
	rentPrice: number;
	rentSchedule: RentSchedule;
	startDate: Date;
	tenants?: Tenant[];
}

export class TenancyAgreement implements TenancyAgreement {
	constructor(data?: Partial<TenancyAgreement>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}