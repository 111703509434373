import { DeviceOs, DeviceType, EventType, PropertyType, RentChargeStatus, RentPaymentType, RentSchedule, TenancyAgreementStatus, UserAddressType, UserSignupMethod, UserStatus, Utility } from "src/app/enums/Index";
import { EnumMap } from "src/app/interfaces/Index";

export function GetPropertiesFromEnum(enumName: typeof DeviceOs | typeof DeviceType | typeof EventType | typeof PropertyType | typeof RentChargeStatus | typeof RentChargeStatus | typeof RentPaymentType | typeof RentSchedule | typeof TenancyAgreementStatus | typeof UserAddressType | typeof UserSignupMethod | typeof UserStatus | typeof Utility): EnumMap[] {
	const arrayObjects: EnumMap[] = [];  
	for (const [propertyKey, propertyValue] of Object.entries(enumName)) {
		if (!Number.isNaN(Number(propertyKey))) continue;
		const name = propertyKey.replace(/(\B[A-Z])/g, " $1").replace(/(\B[0-9]+)/g, " $1")
		arrayObjects.push(new EnumMap({id: (propertyValue as number), name: name.charAt(0).toUpperCase() + name.slice(1)}));
	}
	return arrayObjects;
}
export {}