import { Model } from "./Model";
import { Property } from "./Property";

export interface PropertyDocument extends Model {
	documentType?: string;
	files?: string;
	property?: Property;
	propertyId: number;
	validFrom: Date;
	validTo: Date;
}

export class PropertyDocument implements PropertyDocument {
	constructor(data?: Partial<PropertyDocument>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}