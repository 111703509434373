import { PropertyType } from "src/app/enums/Index";
import { Landlord } from "./Landlord";
import { Model } from "./Model";
import { PropertyDocument } from "./PropertyDocument";
import { PropertyEvent } from "./PropertyEvent";
import { PropertyInventoryItem } from "./PropertyInventoryItem";
import { TenancyAgreement } from "./TenancyAgreement";
import { MeterReading } from "./MeterReading";

export interface Property extends Model {
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	electricityMeterLocation?: string;
	electricitySupplier?: string;
	epcRating?: string;
	gasMeterLocation?: string;
	gasSupplier?: string;
	landlord?: Landlord;
	landlordId?: number;
	meterReadings?: MeterReading[];
	postcode?: string;
	propertyDocuments?: PropertyDocument[];
	propertyEvents?: PropertyEvent[];
	propertyInventoryItems?: PropertyInventoryItem[];
	propertyType: PropertyType;
	serviceChargeRate: number;
	tenancyAgreements?: TenancyAgreement[];
	town?: string;
	waterMeterLocation?: string;
	waterSupplier?: string;
}

export class Property implements Property {
	constructor(data?: Partial<Property>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}