import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ChangePassword } from "src/app/interfaces/Index";
import { UserService } from "src/app/services/Index";
import { colours } from "src/app/Styles";

export const AdminSystemSettings = ({toast}: any) => {
	const [changePassword, setChangePassword] = useState<ChangePassword>(new ChangePassword());
	const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
	const [validationErrors, setValidationErrors] = useState<{[key: string]: string[]}>({});

	const submitChangePassword = async () => {
		setIsChangePasswordLoading(true);
		setValidationErrors({});
		const res = await UserService.updatePassword(changePassword);
		switch(res.status) {
			case 200:
				if (toast.current) (toast.current as any).show({closable: false, life: 5000, severity: "success", summary: "Password Changed Successfully!"});
				setChangePassword(new ChangePassword());
				setIsChangePasswordLoading(false);
				break;
			default:
				const error = await res.json();
				console.log(error)
				if (error.validationErrors) await setValidationErrors(error.validationErrors);
				if (toast.current) (toast.current as any).show({closable: false, detail: error.error, life: 5000, severity: "error", summary: "Something went wrong!"});
				setIsChangePasswordLoading(false);
				break;
		}
	};

	return (
		<>
			<Row>
				<Col>
					<div className="card">
						<h5>System Settings</h5>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="card" style={{marginTop: 10}}>
						<h5>Change Password</h5>
						<div style={{paddingBottom: "1.5rem", paddingLeft: "1.5rem", paddingRight: "1.5rem"}}>
							<Row>
								<Col>
									<InputText disabled={isChangePasswordLoading} id="oldPassword" onChange={(e) => setChangePassword({...changePassword, oldPassword: e.target.value})} placeholder="Old Password" style={{padding: "0.5rem", width: "100%"}} type="password" value={changePassword.oldPassword}/>
									{validationErrors.oldPassword &&
										<small id="oldPassword-help" style={{color: colours.error, display: "block", fontSize: 12}}>{validationErrors.oldPassword.join(" ")}</small>
									}
								</Col>
								<Col>
									<InputText disabled={isChangePasswordLoading} id="newPassword" onChange={(e) => setChangePassword({...changePassword, newPassword: e.target.value})} placeholder="New Password" style={{padding: "0.5rem", width: "100%"}} type="password" value={changePassword.newPassword}/>
									{validationErrors.newPassword &&
										<small id="newPassword-help" style={{color: colours.error, display: "block", fontSize: 12}}>{validationErrors.newPassword.join(" ")}</small>
									}
								</Col>
							</Row>
							<Row>
								<Col>
									<InputText disabled={isChangePasswordLoading} id="confirmNewPassword" onChange={(e) => setChangePassword({...changePassword, confirmNewPassword: e.target.value})} placeholder="Confirm New Password" style={{marginTop: 20, padding: "0.5rem", width: "100%"}} type="password" value={changePassword.confirmNewPassword}/>
									{validationErrors.confirmNewPassword &&
										<small id="confirmNewPassword-help" style={{color: colours.error, display: "block", fontSize: 12}}>{validationErrors.confirmNewPassword.join(" ")}</small>
									}
								</Col>
								<Col>
									<Button iconPos="right" label="Change Password" loading={isChangePasswordLoading} onClick={() => submitChangePassword()} style={{backgroundColor: colours.secondary, borderWidth: 0, color: colours.primary, fontSize: 20, height: 42, marginTop: 20, width: "100%"}}/>
								</Col>
							</Row>
						</div>
						{isChangePasswordLoading === true &&
							<div style={{backgroundColor: colours.transparent, height: "100%", left: 0, position: "absolute", top: 0, width: "100%", zIndex: 19999}}>
								<ProgressSpinner strokeWidth="5" style={{left: "50%", position: "absolute", top: "45%", transform: "translate(-50%, -50%)"}}/>
							</div>
						}
					</div>
				</Col>
			</Row>
		</>
	);
}